import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../common/Styled/CommonStyled";

export const Root = styled(Box)(() => ({
  width:'100vw',
  backgroundColor: "#FFFCF0",
  padding: "40px 16px",
  boxSizing:'border-box',
}));

export const EmptyBox = styled(CardBox)(() => ({
  // height: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "56px 24px",
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
    color: "#333",
    marginTop: 16,
  },
}));
export const SliderWrap = styled(CardBox)(() => ({
  padding: "24px 24px 16px 24px",
  boxSizing:'border-box',
  '& .slick-slide':{
    width:'100%',
    // display:'flex',
  },
  "& .slick-dots": {
    bottom: -50,
    "& li": {
      margin: 0,
    },
  },
}));

export const SlideTitle = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& img": {
    width: "max-content",
  },
  "& span": {
    fontSize: "0.875rem",
    color: "#909195",
    marginTop: 8,
    marginBottom: 4,
  },
  "& p": {
    color: "#333",
    fontSize: "1.375rem",
    fontWeight: 800,
    margin: 0,
  },
}));

export const UlStyle = styled("ul")(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  margin: "16px 0 0 0",
  padding: "0 0 0 21px",
  "& li": {
    width: "100%",
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "#909195",
    marginTop: 2,
    // "&:first-child": {
    "&:first-of-type": {
      marginTop: 0,
    },
  },
}));

export const LatestStyle = styled(Box)(() => ({
  marginTop: 16,
  paddingTop: 16,
  borderTop: "1px solid #F0F1F5",
  "&:hover": {
    cursor: "pointer",
  },
  "& > div": {
    padding: "4px 16px",
    "& > .MuiTypography-root": {
      textAlign: "left",
    },
    "& .MuiTypography-subtitle2": {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#333",
      "& b": {
        color: "#FE536B",
      },
    },
    "& .MuiTypography-body2": {
      fontSize: "0.625rem",
      fontWeight: 400,
      color: "#909195",
    },
  },
}));
export const MoreButton = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  fontSize: "0.75rem",
  fontWeight: 400,
  color: "#909195",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
