import React, {useRef, useState} from "react";

import { Box, Button, Slide, Typography } from "@mui/material";

import { ReactComponent as DialogCloseIcon } from "../../../common/Images/DialogCloseIcon.svg";
import BarChartComponent from "./BarChartComponent";
import LineChartComponent from "./LineChartComponent";
import OverallReviewComponent from "./OverallReviewComponent";
import {
  BoxStyle,
  BoxTitle,
  BoxTitleStyle,
  BoxWrap,
  ButtonMoreView,
  DialogBox,
  IconButtonStyle,
  ProgressBox,
  ProgressOtherBox,
  ProgressUserBox,
  TitleBox,
  TopBox,
} from "./Styled/ReportDetailStyled";
import WordCloudChart from "./WordCloudChart";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import CommonDialog from "../../CommonComponent/CommonDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ReportDetail(props) {
  const {authStore, reportStore} = props;
  const { open, onClose } = props;
  const [chartView, setChartView] = useState(false);
  const reportRef = useRef(null);

  const handleViewToggle = () => {
    setChartView(!chartView);
  };

  const getDate = (reportCreatedDatetime) => {
    return dayjs(reportCreatedDatetime).format('YYYY.MM.DD');
  }

  const getHorizontalBarSize = (count) => {
    const windowWidth = window.innerWidth;

    const userCount = reportStore.report.conversation.userMessageCount;
    const avg = reportStore.report.conversation.allUserMessageCountAverage;
    const maxCount = userCount > avg ? userCount : avg;
    const minCount = userCount > avg ? avg : userCount;

    if (count === maxCount) {
      return (windowWidth * 0.5) + 'px';
    } else {
      return (minCount / maxCount * windowWidth * 0.5) + 'px';
    }
  }

  const getThisWeekUserTime = () => {
    if (reportStore.report.useTime.thisWeek > 0) {
      return Math.round(reportStore.report.useTime.thisWeek / 60);
    } else {
      return 0;
    }
  }

  const getLastWeekUserTime = () => {
    if (reportStore.report.useTime.lastWeek > 0) {
      return Math.round(reportStore.report.useTime.lastWeek / 60);
    } else {
      return 0;
    }
  }

  const getUserTimeDiffString = () => {
    const diff = getThisWeekUserTime() - getLastWeekUserTime();

    if (diff > 0) {
      return diff + '분 더 길어졌어요.';
    } else {
      return Math.abs(diff) + '분 줄어들었어요.';
    }
  }

  const handleClickShare = () => {
    reportStore.setShareDialogOpen(false);
    navigator.share(reportStore.shareData).then(() => console.log("Report share success."));
  }

  return (
    <DialogBox open={open} fullScreen TransitionComponent={Transition}>
      {reportStore.report !== '' &&
        <div ref={reportRef}>
          <TopBox>
            <TitleBox>
              <Typography variant="h5">{((String)(reportStore.report.year)).slice(-2)}년 {reportStore.report.month}월 {reportStore.report.week}주차 리포트</Typography>
              <Typography variant="body2">생성일 : {getDate(reportStore.report.reportCreatedDatetime)}</Typography>
            </TitleBox>
            <IconButtonStyle id='reportCLoseBtn' onClick={onClose} disableRipple>
              <DialogCloseIcon />
            </IconButtonStyle>
          </TopBox>
          <BoxWrap>
            <BoxStyle sx={{ pb: 0 }}>
              <BoxTitle>
                <Typography variant="subtitle1">대화량</Typography>
                <Typography variant="h6">
                  이번주에는 포코로와
                  <br />
                  {reportStore.report.conversation.userMessageCount.toLocaleString()}번을 얘기했어요.
                </Typography>
                <Typography variant="body1">
                  또래 대비 <span>상위 {reportStore.report.conversation.topPercent}%</span>예요
                </Typography>
              </BoxTitle>
              <Box mt={2} mb={3}>
                <ProgressUserBox mb={1}>
                  <Typography variant="subtitle2">{authStore.loginUser.nickname}</Typography>
                  <ProgressBox width={getHorizontalBarSize(reportStore.report.conversation.userMessageCount)} />
                  <Typography variant="body2">{reportStore.report.conversation.userMessageCount.toLocaleString()}</Typography>
                </ProgressUserBox>
                <ProgressOtherBox>
                  <Typography variant="subtitle2">또래평균</Typography>
                  <ProgressBox width={getHorizontalBarSize(reportStore.report.conversation.allUserMessageCountAverage)} />
                  <Typography variant="body2">{reportStore.report.conversation.allUserMessageCountAverage.toLocaleString()}</Typography>
                </ProgressOtherBox>
              </Box>
              <ButtonMoreView>
                {chartView === true && <LineChartComponent userNickname={authStore.loginUser.nickname} data={reportStore.report.conversation.dayOfWeekList} />}
                <Button id='charViewBtn' onClick={handleViewToggle} disableRipple>
                  {chartView === false ? "자세히 보기" : "접기"}
                </Button>
              </ButtonMoreView>
            </BoxStyle>
            <BoxStyle>
              <BoxTitle>
                <Typography variant="subtitle1" style={{ color: "#4CA515" }}>
                  이용시간
                </Typography>
                <Typography variant="h6">
                  이번주에는 포코로와 <br />
                  {getThisWeekUserTime()}분 얘기했어요.
                </Typography>
                <Typography variant="body1">
                  지난주 {getLastWeekUserTime()}분보다 <span>{getUserTimeDiffString()}</span>
                </Typography>
              </BoxTitle>
              <Box mt={3}>
                <BarChartComponent data={reportStore.report.useTime}/>
              </Box>
            </BoxStyle>
            <BoxStyle>
              <BoxTitle>
                <Typography variant="subtitle1" style={{ color: "#868AFF" }}>
                  관심사
                </Typography>
                {reportStore.report.topicWordList.length > 0 &&
                    <React.Fragment>
                      <Typography variant="h6">
                        {reportStore.report.topicWordList[0].word}에 가장 관심을 가지고 있어요.
                      </Typography>
                      <Typography variant="body1">
                        요즘 <b>{reportStore.report.topicWordList[0].word}</b>에 관련한 대화가 크게 늘었어요.
                        {reportStore.report.topicWordList.length > 2 &&
                            <React.Fragment>
                              그 다음으로는 {reportStore.report.topicWordList[1].word}과 {reportStore.report.topicWordList[2].word}에 대해 궁금증을 가지고 있어요.
                            </React.Fragment>
                        }
                      </Typography>
                    </React.Fragment>
                }

              </BoxTitle>
              <Box mt={3}>
                <WordCloudChart wordList={reportStore.report.topicWordList}/>
              </Box>
            </BoxStyle>
          </BoxWrap>
          <Box>
            <BoxTitleStyle>포코로의 총평</BoxTitleStyle>
            <OverallReviewComponent reportRef={reportRef} chartView={chartView} setChartView={setChartView}/>
          </Box>

          <CommonDialog
              open={reportStore.shareDialogOpen}
              title={"리포트를 공유하시겠습니까?"}
              subText={
                <span>
            {reportStore.shareFileTitle}
            <br />
            <br />
            해당 리포트를 공유하시겠습니까?
          </span>
              }
              LeftButtonText={"예"}
              RightButtonText={"아니요"}
              onClick1={handleClickShare}
              onClick2={() => reportStore.setShareDialogOpen(false)}
          />
        </div>
      }
    </DialogBox>
  );
}

export default inject('authStore', 'loadingStore', 'reportStore')(observer(ReportDetail));
