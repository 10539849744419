import React from "react";
import { BoxIn, Loader } from "./Styled/CommonLoadingStyled";
import { Typography } from "@mui/material";
export default function CommonLoading(props) {
  const { text } = props;

  return (
    <BoxIn>
        <Loader>
            <svg className="spinner" viewBox="0 0 48 48">
                <circle className="path" cx="24" cy="24" r="21.5" fill="none" strokeWidth="5"></circle>
            </svg>
        </Loader>
        <Typography variant="body1">
                {text}
        </Typography>
    </BoxIn>
  );
}
