import React from "react";

import { Avatar, Box, Typography } from "@mui/material";

import { ReactComponent as ChevronDown } from "../../common/Icons/ChevronDown.svg";
import ConnectionImage from "../../common/Images/ConnectionImage.png";
import UserDefault from "../../common/Images/UserDefault.png";
import {
  AvatarStyle,
  Caption,
  DrawerContents,
  DrawerStyle,
  DrawerTitle,
  IdBox,
  InfoBox,
  Puller,
  Root,
  SelectButton,
  UserImageIconButton,
  UserInfoBox,
} from "./Styled/HeaderComponentStyled";
import { MoreButton } from "./Styled/RankingComponentStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import CommonDialog from "../CommonComponent/CommonDialog";

function HeaderComponent(props) {
  const {authStore, userStore, modalStore} = props;
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const getDeviceDisplayName = (deviceId) => {
    if (deviceId) {
      return 'POKORO_' + deviceId.substring(0, 4);
    } else {
      return '연결없음';
    }
  }

  const handleClickNewPokoro = () => {
    modalStore.setNewPokoroModalOpen(true);
  }

  const handleClickDevice = (device) => {
    userStore.setNewSelectedDevice(device);
    modalStore.setChangePokoroModalOpen(true);
  }

  const handleChangeDevice = () => {
    userStore.setSelectedDevice(userStore.newSelectedDevice);
    modalStore.setChangePokoroModalOpen(false);
    setOpen(false);
    userStore.getUserWeekOfMonthList(authStore.loginUser.id, (userId) => userStore.getUserDashboard(userId))
  }

  const handleCancelChangeDevice = () => {
    userStore.setNewSelectedDevice('');
    modalStore.setChangePokoroModalOpen(false);
  }

  const handleClickMoveSetting = () => {
    history.push('/setting');
  }

  return (
    <Root>
      <Box>
        <AvatarStyle src={props.deviceImg} alt={getDeviceDisplayName(userStore.selectedDevice.deviceId)} />
        <SelectButton onClick={toggleDrawer(!open)} disableRipple>
          {getDeviceDisplayName(userStore.selectedDevice.deviceId)}
          <ChevronDown style={open ? {transform: 'rotate(180deg)'} : null}/>
        </SelectButton>
        <DrawerStyle
          anchor="top"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerTitle>
            <Typography variant="subtitle1">
              {authStore.loginUser.nickname}(이)가 갖고 있는 포코로 목록
            </Typography>
            <Typography variant="body1">
              포코로를 선택하면 <br /> 선택한 포코로의 데이터로 변경됩니다.
            </Typography>
          </DrawerTitle>
          <DrawerContents>
            <Caption>총 {userStore.userDeviceList.length} 대</Caption>
            {userStore.userDeviceList.map((device, index) => (
                <IdBox
                    disableRipple
                    key={index}
                    className={device === userStore.selectedDevice && "selected"}
                    onClick={() => handleClickDevice(device)}
                >
                  <Avatar src={ConnectionImage}></Avatar>
                  <UserInfoBox>
                    <Typography variant="subtitle2">{getDeviceDisplayName(device.deviceId)}</Typography>
                    <Typography variant="body2">
                      마지막 사용 날짜 <br />
                      {dayjs(device.lastUsedDatetime).format('YYYY. MM. DD')}
                    </Typography>
                  </UserInfoBox>
                </IdBox>
            ))}
          </DrawerContents>
          <MoreButton onClick={handleClickNewPokoro}>새로운 포코로를 추가하려면?</MoreButton>
          <Puller />
        </DrawerStyle>
      </Box>
      <InfoBox>
        <UserImageIconButton disableRipple onClick={handleClickMoveSetting}>
          {authStore.loginUser.profileImageUrl === null || authStore.loginUser.profileImageUrl === '' ?
              <img src={UserDefault} alt={"설정"} />
              :
              <img style={{borderRadius: '50%'}} src={authStore.loginUser.profileImageUrl} alt={"설정"} />
          }
        </UserImageIconButton>
      </InfoBox>

      <CommonDialog
          open={modalStore.changePokoroModalOpen}
          title={"다른 포코로로 변경하시겠습니까?"}
          subText={
            <span>
            현재 연결된 포코로 외 다른 포코로로 변경 시<br />
            홈, 대화목록, 리포트의 내용이 변경됩니다.
            <br />
            그래도 변경하시겠습니까?
          </span>
          }
          onClick1={handleChangeDevice}
          onClick2={handleCancelChangeDevice}
          LeftButtonText={"예"}
          RightButtonText={"아니오"}
      />
    </Root>
  );
}

export default inject('authStore', 'userStore', 'modalStore')(observer(HeaderComponent));
