import React, {useEffect} from "react";

import { Box, Typography, Button } from "@mui/material";

import {DrawerStyle, DateButtonBox, SortButtonBox, Puller, SelectDateButton, PlaceholderText} from "./Styled/FilterDrawerStyled";
import CommonButton from "../../CommonComponent/CommonButton"
import FilterDatePicker from "./FilterDatePicker";
import CommonComfirmDialog from "../../CommonComponent/CommonComfirmDialog";
import {inject, observer} from "mobx-react";


function FilterDrawer(props) {
    const {authStore, userStore, reportStore} = props;

    const {open, onClose} = props;
    const [optionOpen, setOptionOpen] = React.useState(reportStore.searchSection === 'direct');
    const [openDate, setOpenDate] = React.useState(false);

    useEffect(() => {
        reportStore.setStartPickerDate({year: 0, month: 0, week: 0});
        reportStore.setEndPickerDate({year: 0, month: 0, week: 0});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOption = ()  => {
        setOptionOpen(true);
        reportStore.setTempSearchSection('direct');
    };

    const openDatePicker = (newOpen) => () => {
        if (reportStore.selectedDirectSearchType === 'start') {
            reportStore.setStartPickerDate(reportStore.directSearchStartDate);
        } else {
            reportStore.setEndPickerDate(reportStore.directSearchEndDate);
        }

        setOpenDate(newOpen);
    };

    // CheckPb 날짜선택불가
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClickSection = (section) => {
        setOptionOpen(false);
        reportStore.setTempSearchSection(section);
    }

    const handleClickSelectPeriod = (type) => {
        reportStore.setSelectedDirectSearchType(type);
        setOpenDate(true);
    }

    const handleClick = () => {
        reportStore.setSearchSection(reportStore.tempSearchSection);
        reportStore.setSearchSortDirection(reportStore.tempSearchSortDirection);

        if (reportStore.searchSection !== 'direct') {
            setOptionOpen(false);
        }

        reportStore.getReportList(authStore.loginUser.id, userStore.selectedDevice.deviceId, onClose);
    }

    const handleClose = () => {
        if (reportStore.searchSection !== 'direct') {
            setOptionOpen(false);
        } else {
            setOptionOpen(true);
        }

        onClose();
    }

    const getStartDateText = () => {
        if (reportStore.directSearchStartDate.year !== 0) {
            let text = reportStore.directSearchStartDate.year + '년';
            if (reportStore.directSearchStartDate.month !== 0) {
                text = text + ' ' + reportStore.directSearchStartDate.month + '월';
            }
            if (reportStore.directSearchStartDate.week !== 0) {
                text = text + ' ' + reportStore.directSearchStartDate.week + '주차';
            }
            return text;
        } else {
            return '기간선택';
        }
    }

    const getEndDateText = () => {
        if (reportStore.directSearchEndDate.year !== 0) {
            let text = reportStore.directSearchEndDate.year + '년';
            if (reportStore.directSearchEndDate.month !== 0) {
                text = text + ' ' + reportStore.directSearchEndDate.month + '월';
            }
            if (reportStore.directSearchEndDate.week !== 0) {
                text = text + ' ' + reportStore.directSearchEndDate.week + '주차';
            }
            return text;
        } else {
            return '기간선택';
        }
    }

  return (
      <DrawerStyle
          anchor="bottom"
          open={open}
          onClose={handleClose}
      >
          <Puller />
          <Typography variant='h6'>조회기간</Typography>
          <Box mb={4}>
              <DateButtonBox>
                  <Button disableRipple className={reportStore.tempSearchSection === 'all' ? 'active' : null} onClick={() => handleClickSection('all')}>전체</Button>
                  <Button disableRipple className={reportStore.tempSearchSection === '1' ? 'active' : null} onClick={() => handleClickSection('1')}>1개월</Button>
                  <Button disableRipple className={reportStore.tempSearchSection === '3' ? 'active' : null} onClick={() => handleClickSection('3')}>3개월</Button>
                  <Button disableRipple className={reportStore.tempSearchSection === 'direct' ? 'active' : null} onClick={handleClickOption}>직접입력</Button>
              </DateButtonBox>
              {optionOpen === true &&
                  <SelectDateButton disableRipple>
                      <PlaceholderText onClick={() => handleClickSelectPeriod('start')}>
                          {getStartDateText()}
                      </PlaceholderText>
                      <PlaceholderText><span>-</span></PlaceholderText>
                      <PlaceholderText onClick={() => handleClickSelectPeriod('end')}>
                          {getEndDateText()}
                      </PlaceholderText>
                  </SelectDateButton>
              }
          </Box>
          <Typography variant='h6'>정렬</Typography>
          <SortButtonBox>
              <Button disableRipple className={reportStore.tempSearchSortDirection === 'descending' ? 'active' : null} onClick={() => reportStore.setTempSearchSortDirection('descending')}>최신순</Button>
              <Button disableRipple className={reportStore.tempSearchSortDirection === 'ascending' ? 'active' : null} onClick={() => reportStore.setTempSearchSortDirection('ascending')}>과거순</Button>
          </SortButtonBox>

          <CommonButton
              disabled={(reportStore.tempSearchSection === 'direct' && (reportStore.directSearchStartDate.year === 0 || reportStore.directSearchEndDate.year === 0))}
              background={'#FDD751'}
              color={'#333'}
              text={'확인'}
              onClick={handleClick}
          />

          {/* 날짜 선택 불가 */}
          <CommonComfirmDialog
              open={openDialog}
              title = {'조회 시작 기간 이전 날짜 선택 불가'}
              contents={<>조작 시작 기간을 조회 끝 기간보다<br/>
                  '이전으로 선택해주세요.</>}
              buttonText={'확인'}
              onClick={handleClickCloseDialog}
          />

          {/* 날짜 선택 */}
          <FilterDatePicker open={openDate} onClose={openDatePicker(false)} setOpenDialog={setOpenDialog}/>
      </DrawerStyle>
  );
}

export default inject('authStore', 'userStore', 'reportStore')(observer(FilterDrawer));