import React, {useEffect} from "react";

import Device from "../../common/Images/ConnectionImage.png";
import { ListCaption, ListTitle } from "../../common/Styled/CommonStyled";
import BottomNavigationComponent from "./BottomNavigationComponent";
import Conversation from "./Conversation/Conversation";
import ConversationComponent from "./ConversationComponent";
import HeaderComponent from "./HeaderComponent";
import RankingComponent from "./RankingComponent";
import Report from "./Report/Report";
import ReportComponent from "./ReportComponent";
import { Container, EmptyBox, Root } from "./Styled/HomeStyled";
import {inject, observer} from "mobx-react";
import PoKoRoAddDialog from "./PoKoRoAddDialog";

function Home(props) {
    const [tabValue, setTabValue] = React.useState("board");
    const {authStore, userStore, loadingStore, conversationStore, reportStore} = props;

    const handleChangeTabs = (event, newTabValue) => {
        setTabValue(newTabValue);
        if (tabValue !== newTabValue) {
            if (newTabValue === 'board') {
                userStore.getUserDashboard(authStore.loginUser.id);
            }
            if (newTabValue === 'conversation') {
                if (event !== null) {
                    conversationStore.init();
                }
            }
            if (newTabValue === 'report') {
                reportStore.init();
            }
        }
    };


    useEffect(() => {
        loadingStore.setIsLoading(userStore.getIsLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore.getIsLoading]);

    useEffect(() => {
        userStore.getUserDeviceList(authStore.loginUser.id, (userId) => userStore.getUserDashboard(userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Root>
      {tabValue === "board" && (
        <div>
          <HeaderComponent deviceImg={Device} />
            {userStore.selectedDevice === '' ?
                <EmptyBox>
                    <ListTitle variant="h3">
                        연결된 포코로가 없습니다.
                    </ListTitle>
                    <ListCaption>
                        포코로 연결 목록을 열어 <br />
                        포코로를 추가하면 이용할 수 있습니다.
                    </ListCaption>
                </EmptyBox>
                :
                <React.Fragment>
                    {userStore.userWeekOfMonthList.length === 0 ?
                        <EmptyBox>
                            <ListTitle variant="h3">
                                서버에서 데이터를 <br />
                                불러오지 못했습니다.
                            </ListTitle>
                            <ListCaption>
                                서버가 응답하지 않아 <br />
                                새로고침을 하거나 서비스를 종료하고
                                <br />
                                다시 실행해주시길 바랍니다.
                            </ListCaption>
                        </EmptyBox>
                        :
                        <Container>
                            {userStore.userDashboardData !== '' ?
                                <React.Fragment>
                                    <RankingComponent handleChangeTab={handleChangeTabs} />
                                    <ReportComponent handleChangeTab={handleChangeTabs} />
                                    <ConversationComponent handleChangeTab={handleChangeTabs} />
                                </React.Fragment>
                                :
                                <EmptyBox>
                                    <ListTitle variant="h3">
                                        서버에서 데이터를 <br />
                                        불러오지 못했습니다.
                                    </ListTitle>
                                    <ListCaption>
                                        서버가 응답하지 않아 <br />
                                        새로고침을 하거나 서비스를 종료하고
                                        <br />
                                        다시 실행해주시길 바랍니다.
                                    </ListCaption>
                                </EmptyBox>
                            }
                        </Container>
                    }
                </React.Fragment>
            }
        </div>
      )}

      {tabValue === "conversation" && (
          <Conversation />
      )}
      {tabValue === "report" && <Report />}

      <BottomNavigationComponent
        value={tabValue}
        handleChangeTab={handleChangeTabs}
      />

        {/* dialog */}
        <PoKoRoAddDialog />
    </Root>
  );
}

export default inject('authStore', 'userStore', 'loadingStore', 'conversationStore', 'reportStore')(observer(Home));
