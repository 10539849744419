import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(() => ({}));

export const CardBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  border: "2px solid #F0F1F5",
  borderRadius: 20,
  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.05)",
  padding: "32px  16px",
  margin: "16px 0",
}));

export const SolidBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: 16,
  padding: "24px 16px",
  margin: "8px 0",
}));

export const TitleStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.25rem",
    fontWeight: 800,
    color: "#333",
  },
}));

export const ListTitle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1rem",
    fontWeight: 700,
    color: "#333",
    "& span": {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },
}));

export const ListCaption = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "#909195",
  },
}));

export const Loader = styled('span')(() => ({
  position:'relative',
  width : 48,
  height: 48,
  borderRadius: '50%',
  display: 'inline-block',
  border: '5px solid #F0F1F5',
  boxSizing:'border-box',
  '& .spinner':{
    position: 'absolute',
    top: -5,
    left: -5,
    width: 48,
    height: 48,
  },
  '& .path': {
    position:'absolute',
    top:0,
    left:0,
    stroke: '#FDD751',
    strokeLinecap: 'round',
    animation: `dash 1s ease-in-out infinite`,
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%' :{transform: 'rotate(360deg)'},
  },
  '@keyframes dash':{
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: -35,
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: -124,
    }
  }
}));
