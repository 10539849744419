import React from "react";

import { Box } from "@mui/material";

import Chart from "react-apexcharts";
import {styled} from "@mui/material/styles";
import {observer} from "mobx-react";


const Root = styled(Box)(() => ({
   '& .apexcharts-toolbar':{
       display:'none',
   },
}));

function LineChartComponent(props) {
    const dataList = props.data;

    let userData = [];
    let avgData = [];
    for (const data of dataList) {
        userData.push(Number(data.userMessageCount))
        avgData.push(Number(data.allUserMessageCountAverage))
    }

    const options = {
        xaxis: {
            categories: ["월", "화", "수", "목", "금", "토", "일"],
            labels: {
                style: {
                    colors: "#909195",
                    fontSize:"0.625rem",
                }
            },
        },
        yaxis: {
            axisBorder: {
                show: true,
            },
            labels: {
                style: {
                    colors: "#909195",
                    fontSize:"0.625rem",
                }
            },
        },
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        colors: ['#FDD751', '#C9C9CA'],
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 5,
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            strokeWidth: 0,
        },
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        grid: {
            // show:false,
            row: {
                colors: ['#fff', 'transparent'],
                opacity: 0.5,
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        },
        tooltip: {
            enabled: false
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
            fontSize: '10px',
            width: undefined,
            height: undefined,
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 29,
                height: 8,
                strokeWidth: 0,
                strokeColor: '#fff',
                radius: 0,
                offsetX: 0,
                offsetY: 0
            },
        }
    };
    const series = [
        {
            name: `${props.userNickname}`,
            data: userData
        },
        {
            name: "또래",
            data: avgData
        },
    ];

    return (
        <Root>
            <Chart options={options} series={series} type="line" height={300}/>
        </Root>
    );
}

export default (observer(LineChartComponent));
