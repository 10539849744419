import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(() => ({
  display:'block',
  width:'100%',
}));

export const Container = styled(Box)(() => ({
  paddingTop: 69,
  paddingBottom: 75,
}));

export const EmptyBox = styled(Box)(() => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 32,
  "& .MuiTypography-root": {
    textAlign: "center",
  },
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
  },
}));
