import {
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { TitleStyle } from "../../../../common/Styled/CommonStyled";
export const DialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    backgroundColor: "#F0F1F5",
  },
  "& .MuiPaper-root": {
    // minWidth: 328,
    // padding: "32px 24px",
    boxSizing: "border-box",
    backgroundColor: "transparent",
  },
}));

export const TopBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: 16,
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  "&.MuiButtonBase-root": {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
}));

export const TitleBox = styled(Box)(() => ({
  "& .MuiTypography-h5": {
    fontSize: "1.125rem",
    color: "#333",
    fontWeight: 700,
    lineHeight: "25.2px",
    textAlign: "left",
  },
  "& .MuiTypography-body2": {
    fontSize: "0.625rem",
    color: "#909195",
    fontWeight: 400,
    textAlign: "left",
  },
}));
export const BoxWrap = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));

export const BoxStyle = styled(Box)(() => ({
  backgroundColor: "#fff",
  padding: "32px 16px",
}));
export const BoxTitle = styled(Box)(() => ({
  "& .MuiTypography-subtitle1": {
    color: "#FE536B",
    fontSize: "0.875rem",
    fontWeight: 400,
    textAlign: "left",
    marginBottom: 8,
  },
  "& .MuiTypography-h6": {
    fontSize: "1.125rem",
    color: "#333",
    fontWeight: 700,
    lineHeight: "25.2px",
    textAlign: "left",
  },
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
    color: "#333",
    fontWeight: 400,
    textAlign: "left",
    "& > span": {
      color: "#FF993C",
    },
  },
}));

export const ButtonMoreView = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderTop: "1px solid #F0F1F5",
  marginTop: 16,
  "& > button": {
    width: "100%",
    padding: 16,
    color: "#909195",
    fontSize: "0.75rem",
    fontWeight: 400,
    "&:hover":{
      background:"transparent",
    },
  },
}));

export const ProgressUserBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  "& .MuiTypography-subtitle2": {
    minWidth: 50,
    fontSize: "0.75rem",
    fontWeight: 400,
  },
}));
export const ProgressOtherBox = styled(ProgressUserBox)(() => ({
  "& > div": { backgroundColor: "#C9C9CA" },
  "& .MuiTypography-body2": {
    fontSize: "0.75rem",
    color: "#909195",
  },
}));
export const ProgressBox = styled(Box)(() => ({
  height: 19,
  marginRight: 8,
  backgroundColor: "#FDD751",
  borderRadius: 0,
  transition: "all 2s ease-out",
}));

export const BoxTitleStyle = styled(TitleStyle)(() => ({
  fontSize: "1.125rem!important",
  padding: "40px 16px 0 ",
}));

export const NotifyBox = styled(Box)(() => ({
  borderTop: "1px solid #F0F1F5",
  paddingTop: 24,
  marginTop: 24,
  "& .MuiTypography-subtitle2": {
    fontSize: "0.875rem",
    fontWeight: 700,
    color: "#333",
    "& b": {
      color: "#FF993C",
    },
  },
  "& .MuiTypography-body2": {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "#909195",
  },
}));
