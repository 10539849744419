import React from "react";

import { ReactComponent as Search } from "../../common/Icons/Search.svg";
import {
  ErrorInputText,
  SearchIconButton,
  StyledInput,
} from "./Styled/CommonInputStyled";
import {observer} from "mobx-react";

function CommonInput(props) {
  const { value, onChange, placeholder, error, errorMessage, searchIcon } = props;

  return (
    <div>
      <StyledInput
        error={error}
        label=""
        value={value === null ? '' : value}
        variant="outlined"
        fullWidth
        placeholder={placeholder}
        onChange={onChange}
        InputProps={
          searchIcon && {
            startAdornment: (
              <SearchIconButton position="start">
                <Search />
              </SearchIconButton>
            ),
          }
        }
      />
      {error && <ErrorInputText>{errorMessage}</ErrorInputText>}
    </div>
  );
}

export default (observer(CommonInput));