import React, {useEffect} from "react";

import { MenuItem } from "@mui/material";

import { ReactComponent as ChevronDown } from "../../common/Icons/ChevronDown.svg";
import { ReactComponent as InfoTiny } from "../../common/Icons/InfoTiny.svg";
import {
  BodyStyle,
  ButtonStyle,
  InfoText,
  MenuStyle,
  SelectBox,
  SubtitleStyle,
} from "./Styled/SelectDateComponentStyled";
import {inject, observer} from "mobx-react";

function SelectDateComponent(props) {
    const {authStore, userStore} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        function handleClickScreen() {
            if (open) {
                setAnchorEl(null);
            }
        }
        document.addEventListener('click', handleClickScreen);

        return () => {
            document.removeEventListener('click', handleClickScreen);
        };
    }, [open]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickWeekOfMonth = (weekOfMonth) => {
        setAnchorEl(null);
        userStore.setSelectedWeekOfMonth(weekOfMonth);
        userStore.getUserDashboard(authStore.loginUser.id);
    }

  return (
    <SelectBox>
      <ButtonStyle
        aria-describedby={"dateSelect"}
        onClick={handleClick}
        endIcon={<ChevronDown />}
        disableRipple
      >
        <SubtitleStyle variant="subtitle1">{userStore.selectedWeekOfMonth.month}월 {userStore.selectedWeekOfMonth.week}주차&nbsp;</SubtitleStyle>
        <BodyStyle>({userStore.selectedWeekOfMonth.startDay} ~ {userStore.selectedWeekOfMonth.endDay})</BodyStyle>
      </ButtonStyle>
      <SubtitleStyle variant="subtitle1">
          {authStore.loginUser.nickname}(이)가 가장 많이 말한 단어는?
      </SubtitleStyle>
      <InfoText>
        <InfoTiny />한 주간 가장 많이 쓴 단어는 3개까지 보여드립니다.
      </InfoText>
      {/* Popper */}
      <MenuStyle id={"dateSelect"} open={open} anchorEl={anchorEl}>
        {userStore.userWeekOfMonthList.map((weekOfMonth, index) => (
          <MenuItem onClick={() => handleClickWeekOfMonth(weekOfMonth)} key={index} disableRipple style={weekOfMonth === userStore.selectedWeekOfMonth ? {backgroundColor: '#FDD751'} : null}>
              {weekOfMonth.month}월 {weekOfMonth.week}주차
              ({weekOfMonth.startDay} ~ {weekOfMonth.endDay})
          </MenuItem>
        ))}
      </MenuStyle>
      {/*  //Popper */}
    </SelectBox>
  );
}

export default inject('authStore', 'userStore')(observer(SelectDateComponent));