import React from "react";

import { Typography } from "@mui/material";

import { ReactComponent as ConnectionIcon } from "../../common/Images/ConnectionIcon.svg";
import ConnectionImage from "../../common/Images/ConnectionImage.png";
import { ReactComponent as DialogCloseIcon } from "../../common/Images/DialogCloseIcon.svg";
import { ReactComponent as PoKoRoImage} from "../../common/Icons/PoKoRoImage.svg";
import {
  ButtonStyle,
  DialogBox,
  IconButtonStyle,
  ImageBox,
  TextStyle,
  TitleText,
  TopBox,
} from "./Styled/PoKoRoAddDialogStyled";
import {inject, observer} from "mobx-react";
import {AndroidPackageName, IosAppId} from "../Setting/Setting";


function PoKoRoAddDialog(props) {
    const {modalStore} = props;

    const handleClickClose = () => {
        modalStore.setNewPokoroModalOpen(false);
    }

    const handleClickMoveStore = () => {
        const agent = navigator.userAgent.toLowerCase();

        if (/(mac|iphone|ipod|ipad)/i.test(agent)) {
            window.open('https://apps.apple.com/kr/app/' + IosAppId, '_blank');
        } else {
            window.open('https://play.google.com/store/apps/details?id=' + AndroidPackageName, '_blank');
        }
    }

  return (
    <DialogBox open={modalStore.newPokoroModalOpen}>
      <TopBox>
        <IconButtonStyle onClick={handleClickClose} disableRipple>
          <DialogCloseIcon />
        </IconButtonStyle>
      </TopBox>

      <TitleText>새로운 포코로를 추가하려면?</TitleText>
      <TextStyle>
        포코로를 추가하려면 <span>&#39;포코로 커넥트&#39;</span> 앱을 통해
        <br />
        포코로에 와이파이를 한번 연결한 뒤 대화를 하면
        <br />
        자동으로 포코로 대시보드로 데이터를 가져올 수
        <br />
        있습니다.
      </TextStyle>

      <ImageBox>
        {/*<img src={PoKoRoImage} alt={"포코로 이미지"} />*/}
          <PoKoRoImage/>
        <ConnectionIcon />
        <img src={ConnectionImage} alt={"연결 기기 이미지"} />
      </ImageBox>

      <ButtonStyle disableRipple onClick={handleClickMoveStore}>
        <Typography>스토어로 이동</Typography>
      </ButtonStyle>
    </DialogBox>
  );
}

export default inject('modalStore')(observer(PoKoRoAddDialog));
