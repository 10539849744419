import { Repository } from './Repository';

export default class ConversationRepository extends Repository {
    constructor() {
        super();

        this.conversationRequestPrefix = '/api/v1/dialog';
    }

    getConversationList = (userId, deviceId, searchKeyword, rowsPerPage, page, sortDirection) => {
        return this.getRequestPromise('get', this.conversationRequestPrefix + `?userId=${userId}&deviceId=${deviceId}&searchKeyword=${searchKeyword}&rowsPerPage=${rowsPerPage}&page=${page}&sortDirection=${sortDirection}`);
    };

    getConversation = (dialogId) => {
        return this.getRequestPromise('get', this.conversationRequestPrefix + `/${dialogId}`);
    };
}
