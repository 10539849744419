import React from "react";

import { BottomNavigationAction } from "@mui/material";

import { ReactComponent as HomeBN } from "../../common/Icons/HomeBN.svg";
import { ReactComponent as HomeBNActive } from "../../common/Icons/HomeBNActive.svg";
import { ReactComponent as SliceBN } from "../../common/Icons/SliceBN.svg";
import { ReactComponent as SliceBNActive } from "../../common/Icons/SliceBNActive.svg";
import { ReactComponent as TermBN } from "../../common/Icons/TermBN.svg";
import { ReactComponent as TermBNActive } from "../../common/Icons/TermBNActive.svg";
import { Root } from "./Styled/BottomNavigationComponent";
export default function BottomNavigationComponent(props) {
  const { value, handleChangeTab } = props;
  return (
    <Root showLabels value={value} onChange={handleChangeTab}>
      <BottomNavigationAction
        label="포코로 보드"
        value="board"
        disableRipple
        icon={
          value === "board" ?
              // <img src={HomeBNActive} alt='Home'/> : <img src={HomeBN} alt='Home'/>
            <HomeBNActive /> : <HomeBN />
        }
      />
      <BottomNavigationAction
        label="대화목록"
        value="conversation"
        icon={
          value === "conversation" ?
              // (<img src={TermBNActive} alt='Dialog' /> ) : (<img src={TermBN} alt='Dialog' />)
              <TermBNActive /> : <TermBN />
        }
        disableRipple
      />
      <BottomNavigationAction
        label="리포트"
        value="report"
        icon={
          value === "report" ?
              // (<img src={SliceBNActive} alt='Report' />) : ( <img src={SliceBN} alt='Report' />)
              <SliceBNActive /> : <SliceBN />
        }
        disableRipple
      />
    </Root>
  );
}
