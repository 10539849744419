import React from "react";

import CommonBack from "../CommonComponent/CommonBack";
import CommonButton from "../CommonComponent/CommonButton";
import CommonInput from "../CommonComponent/CommonInput";
import SignInText from "./SignInText";
import {
  BoxMarginTop,
  InfoBox,
  InfoText,
  SignInContentsBox,
  SignInRoot,
} from "./Styled/SignInStyled";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import CommonDialog from "../CommonComponent/CommonDialog";

function SignInInfo(props) {
  const {authStore} = props;
  const history = useHistory();

  const handleChangeNickname = (e) => {
    authStore.setNickname(e.target.value);
  }

  const handleClick = () => {
    authStore.updateNickname(history);
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const moveLoginPage = () => {
    authStore.doLogout(() => history.push('/'));
  }

  return (
    <SignInRoot>
      <CommonBack onClick={handleClickOpen} />

      <SignInContentsBox>
        <InfoBox>
          <SignInText
            title={"이름 또는 닉네임 입력"}
            text={"포코로를 사용하는 아이의 이름을 입력해주세요. "}
          />
          <BoxMarginTop>
            <CommonInput
                value={authStore.nickname}
                onChange={handleChangeNickname}
                placeholder={"이름 또는 닉네임"}
                error={authStore.isNotAcceptableNickname() !== ''}
                errorMessage={authStore.isNotAcceptableNickname()}
            />
          </BoxMarginTop>

          <InfoText>
            <li>
              <span>2자 이상 8자 이내</span>
            </li>
            <li>
              <span>자음만, 모음만, 특수문자 입력 불가</span>
            </li>
            <li>
              <span>욕설 입력 불가</span>
            </li>
          </InfoText>
        </InfoBox>

        <CommonButton text={"확인"} background={"#FDD751"} color={"#333"} disabled={(authStore.isNotAcceptableNickname() !== '') || authStore.nickname === ''} onClick={handleClick} />
      </SignInContentsBox>

      <CommonDialog
          open={open}
          title={"로그인 페이지로 이동하시겠습니까?"}
          subText={
            <span>
            현재 로그인된 상태입니다.<br />
            이동할 경우 자동 로그아웃 처리됩니다.
            <br />
            그래도 이동하시겠습니까?
          </span>
          }
          onClick1={moveLoginPage}
          onClick2={handleClickClose}
          LeftButtonText={"예"}
          RightButtonText={"아니오"}
      />
    </SignInRoot>
  );
}

export default inject('authStore')(observer(SignInInfo));
