import {makeAutoObservable} from "mobx";

const logPrefix  = "[UserStore]"
export default class UserStore {
    constructor(props) {
        this.reportRepository = props.reportRepository;

        this.isReportistLoading = false;
        this.isReporLoading = false;
        this.isReportDownloadLoading = false;

        this.reportList = [];
        this.deleteReport = '';
        this.report = '';

        this.shareData = '';
        this.shareDialogOpen = false;
        this.shareFileTitle = '';

        this.init();

        makeAutoObservable(this);
    }

    init = () => {
        this.searchSortDirection = 'descending';
        this.searchSection = 'all';
        this.selectedDirectSearchType = 'start';

        this.tempSearchSortDirection = 'descending';
        this.tempSearchSection = 'all';

        this.directSearchStartDate = {year: 0, month: 0, week: 0};
        this.directSearchEndDate = {year: 0, month: 0, week: 0};
        this.startPickerDate = {year: 0, month: 0, week: 0};
        this.endPickerDate = {year: 0, month: 0, week: 0};

        this.isDetailOpen = false;
    }

    get getIsLoading() {
        return this.isReportistLoading || this.isReporLoading || this.isReportDownloadLoading;
    };

    setIsReportDownloadLoading = (isLoading) => {
        this.isReportDownloadLoading = isLoading;
    }

    setDeleteReport = (report) => {
        this.deleteReport = report;
    }

    setIsDetailOpen = (open) => {
        this.isDetailOpen = open;
    }

    setSearchSortDirection =  (sortDirection) => {
        this.searchSortDirection = sortDirection;
    }

    setSearchSection = (section) => {
        this.searchSection = section;
    }

    setSelectedDirectSearchType = (type) => {
        this.selectedDirectSearchType = type;
    }

    setDirectSearchStartDate = (date) => {
        if (date.hasOwnProperty('year')) {
            this.directSearchStartDate.year = date.year;
        }

        if (date.hasOwnProperty('month')) {
            this.directSearchStartDate.month = date.month;
        }

        if (date.hasOwnProperty('week')) {
            this.directSearchStartDate.week = date.week;
        }
    }

    setDirectSearchEndDate = (date) => {
        if (date.hasOwnProperty('year')) {
            this.directSearchEndDate.year = date.year;
        }

        if (date.hasOwnProperty('month')) {
            this.directSearchEndDate.month = date.month;
        }

        if (date.hasOwnProperty('week')) {
            this.directSearchEndDate.week = date.week;
        }
    }

    setStartPickerDate = (date) => {
        this.startPickerDate = date;
    }

    setEndPickerDate = (date) => {
        this.endPickerDate = date;
    }

    setTempSearchSortDirection = (direction) => {
        this.tempSearchSortDirection = direction;
    }

    setTempSearchSection = (section) => {
        this.tempSearchSection = section;
    }

    setShareData = (data) => {
        this.shareData = data;
    }

    setShareDialogOpen = (open) => {
        this.shareDialogOpen = open;
    }

    setShareFileTitle = (title) => {
        this.shareFileTitle = title;
    }

    * getReportList(userId, deviceId, callback) {
        try {
            console.log(logPrefix, 'Start getReportList.');
            this.isReportistLoading = true;

            const response = yield this.reportRepository.getReportList(userId,
                                                                    deviceId,
                                                                    this.searchSortDirection,
                                                                    this.searchSection,
                                                                    this.directSearchStartDate.year,
                                                                    this.directSearchStartDate.month,
                                                                    this.directSearchStartDate.week,
                                                                    this.directSearchEndDate.year,
                                                                    this.directSearchEndDate.month,
                                                                    this.directSearchEndDate.week);
            this.reportList = response.data;

            this.isReportistLoading = false;
            console.log(logPrefix, 'Finished getReportList.');

            callback && callback();
        } catch(e) {
            this.reportList = '';
            console.log(logPrefix, 'Failed getReportList. error: ' + e);
        } finally {
            this.isReportistLoading = false;
        }
    }

    * getReport(report, callback) {
        try {
            console.log(logPrefix, 'Start getReport.');
            this.isReporLoading = true;

            const response = yield this.reportRepository.getReport(report.userId, report.deviceId, report.year, report.month, report.week, false);
            this.report = response.data;

            this.isReporLoading = false;
            console.log(logPrefix, 'Finished getReport.');

            callback && callback();
        } catch(e) {
            this.report = '';
            console.log(logPrefix, 'Failed getReport. error: ' + e);
        } finally {
            this.isReporLoading = false;
        }
    }

    * getNewReport(callback) {
        try {
            console.log(logPrefix, 'Start getNewReport.');
            this.isReporLoading = true;

            const response = yield this.reportRepository.getReport(this.report.userId, this.report.deviceId, this.report.year, this.report.month, this.report.week, true);
            this.report = response.data;

            this.isReporLoading = false;
            console.log(logPrefix, 'Finished getNewReport.');

            const targetReportList = this.reportList.filter((report) => report.year === this.report.year && report.month === this.report.month && report.week === this.report.week);
            if (targetReportList.length > 0) {
                targetReportList[0].reportCreatedDatetime = this.report.reportCreatedDatetime;
            }
            callback && callback();
        } catch(e) {
            this.report = '';
            console.log(logPrefix, 'Failed getNewReport. error: ' + e);
        } finally {
            this.isReporLoading = false;
        }
    }

    * updateReportDisplay(userId, deviceId, callback) {
        try {
            console.log(logPrefix, 'Start updateReportDisplay.');

            this.deleteReport.display = false;
            yield this.reportRepository.updateReportDisplay(this.deleteReport);

            console.log(logPrefix, 'Finished updateReportDisplay.');

            callback && callback();
        } catch(e) {
            this.deleteReport = '';
            console.log(logPrefix, 'Failed updateReportDisplay. error: ' + e);
        }
    }
}
