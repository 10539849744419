import React, {useEffect} from "react";

import { Box, FormControl, Typography } from "@mui/material";

import { ReactComponent as Refresh } from "../../../common/Icons/Refresh.svg";
import { ReactComponent as Search } from "../../../common/Icons/Search.svg";
import { ReactComponent as SearchErrorIcon } from "../../../common/Icons/SearchErrorIcon.svg";
import EmptyImage from "../../../common/Icons/EmptyImage.svg";
import PoKoRoSadImage from "../../../common/Images/PoKoRoSadImage.png";
import QuestionPokoro from "../../../common/Icons/QuestionPokoro.svg";
import CommonEmptyPoKoRo from "../../CommonComponent/CommonEmptyPoKoRo";
import { SearchIconButton } from "../../CommonComponent/Styled/CommonInputStyled";
import ConversationContentComponent from "./ConversationContentComponent";
import {
  ButtonIconStyle,
  CommonInputStyle,
  ControlBox,
  MenuItemStyle,
  Root,
  SelectStyle,
  TitleTextStyle,
  ButtonText,
  ErrorBox,
} from "./Styled/ConversationListStyled";
import {inject, observer} from "mobx-react";
import { ReactComponent as KeyboardArrowDownIcon } from "../../../common/Icons/ChevronDown.svg";
import CommonLoading from "../../CommonComponent/CommonLoading";


// const SelectArrowIcon = () => {
//   return <ChevronDown width={"48px"} style={{pointerEvents: 'none !important'}}/>;
// };

function ConversationList(props) {
    const {authStore, userStore, conversationStore} = props;

    useEffect(() => {
        conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getConversationList = () => {
        conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId);
    }

    const handleChangeSortDirection = (event) => {
        conversationStore.setSearchSortDirection(event.target.value);
        getConversationList();
    };

    const handleChangeSearchKeyword = (e) => {
        conversationStore.setTempSearchKeyword(e.target.value);
    }

    const handleKeyPressSearch = (e) => {
        e.preventDefault();
        if (e.keyCode === 13) {
            conversationStore.setSearchKeyword(conversationStore.tempSearchKeyword);
            conversationStore.setPage(1);
            getConversationList();
        }
    }

  return (
    <Root>
      <TitleTextStyle>대화 목록</TitleTextStyle>
      <Box>
        <CommonInputStyle
          label=""
          variant="outlined"
          fullWidth
          placeholder="아이가 무슨 대화를 했는지 검색해보세요."
          value={conversationStore.tempSearchKeyword}
          onChange={handleChangeSearchKeyword}
          onKeyUp={handleKeyPressSearch}
          InputProps={{
            startAdornment: (
              <SearchIconButton position="start">
                <Search />
              </SearchIconButton>
            ),
          }}
        />
      </Box>
      <ControlBox mt={3} mb={1}>
        <FormControl variant="standard">
          <SelectStyle
            value={conversationStore.searchSortDirection}
            onChange={handleChangeSortDirection}
            label="SortDirection"
            style={{width: 80}}
            displayEmpty
            // IconComponent={SelectArrowIcon}
            IconComponent={() => (
                <KeyboardArrowDownIcon style={{
                    position: 'absolute',
                    top: '2px',
                    left: '34px',
                    display: 'inline-block',
                    width: '48px',
                    pointerEvents: 'none'
                }} />
            )}
          >
            <MenuItemStyle value="descending">최신순</MenuItemStyle>
            <MenuItemStyle value="ascending">오름차순</MenuItemStyle>
          </SelectStyle>
        </FormControl>
        <ButtonIconStyle disableRipple onClick={getConversationList}>
          <Typography>새로고침</Typography> <Refresh />
        </ButtonIconStyle>
      </ControlBox>

        {conversationStore.isConversationListLoading ?
            <CommonLoading text={<>검색 결과를 로딩 중입니다.<br/> 잠시만 기다려주세요.</>} />
            :
            <React.Fragment>
                {userStore.selectedDevice === '' ?
                    <CommonEmptyPoKoRo
                        image={EmptyImage}
                        text={
                            <Typography>
                                연결된 포코로가 없어
                                <br />
                                대화목록을 불러올 수가 없습니다.
                            </Typography>
                        }
                    />
                    :
                    <React.Fragment>
                        {conversationStore.conversationList === '' ?
                            <ErrorBox>
                                <SearchErrorIcon/>
                                <Typography variant="body1">
                                    알 수 없는 오류로 인해 <br/> 검색 결고를 불러오지 못했습니다. <br/> 다시 시도해주세요.
                                </Typography>
                                <ButtonText onClick={getConversationList}> 새로고침 </ButtonText>
                            </ErrorBox>
                            :
                            <React.Fragment>
                                {conversationStore.conversationList.length === 0 ?
                                    <React.Fragment>
                                        {conversationStore.searchKeyword === '' ?
                                            <CommonEmptyPoKoRo
                                                image={PoKoRoSadImage}
                                                text={
                                                    <Typography>
                                                        아직 포코로에 쌓인 대화가 부족합니다.
                                                        <br />
                                                        충분한 대화를 한 뒤 다시 접속해주세요 :)
                                                    </Typography>
                                                }
                                            />
                                            :
                                            <CommonEmptyPoKoRo
                                                image={QuestionPokoro}
                                                text={<Typography>검색된 결과가 없습니다.</Typography>}
                                            />
                                        }

                                    </React.Fragment>
                                    :
                                    <ConversationContentComponent />
                                }
                            </React.Fragment>
                        }

                    </React.Fragment>
                }
            </React.Fragment>
        }
    </Root>
  );
}

export default inject('authStore', 'userStore', 'conversationStore')(observer(ConversationList));