import React from "react";

import { Box, Typography } from "@mui/material";

import SecretPokoro from "../../../common/Images/SecretPokoro.png";
import { SolidBox } from "../../../common/Styled/CommonStyled";
import CommonButton from "../../CommonComponent/CommonButton";
import {
  ContentBox,
  DividerLine,
  HashtagBox,
  Root,
  TitleBox,
  ActionBox
} from "./Styled/OverallReviewComponentStyled";
import {inject, observer} from "mobx-react";
import FileShareDrawer from "./FileShareDrawer";

function OverallReviewComponent(props) {
  const {reportStore, reportRef, setChartView} = props;
  const [shareOpen, setShareOpen] = React.useState(false);

  const toggleDrawerShare = (newShareOpen) => () => {
    setShareOpen(newShareOpen);
  };

  const handleClickRefresh = () => {
    reportStore.getNewReport();
  }

  return (
    <Root>
      {reportStore.report !== '' &&
          <SolidBox>
            <TitleBox>
              <img src={SecretPokoro} alt={""} />
              <Typography variant="subtitle1" pl={1}>
                {'"'}{reportStore.report.review.title}{'"'}
              </Typography>
            </TitleBox>
            <ContentBox>
              <Typography variant="body1">
                {reportStore.report.review.contents}
              </Typography>
              <DividerLine />
              <Typography variant="subtitle2">
                포코로의 추천 항목
              </Typography>
              <ul>
                {reportStore.report.curriculumList.map((curriculum, index) => (
                    <li key={index}>
                      <b>{curriculum.title}</b>: {curriculum.contents}
                    </li>
                ))}
              </ul>
              <DividerLine />
              <HashtagBox>
                {reportStore.report.tagList.map((tag, index) => (
                    <Box key={index}>{tag}</Box>
                ))}
              </HashtagBox>
              <DividerLine />
              <ActionBox>
              <CommonButton
                  disabled={!navigator.share}
                  id='reportShareBtn'
                  text={"공유하기"}
                  border={"#C9C9CA"}
                  color={"#909195"}
                  onClick={toggleDrawerShare(true)}
              />
                <CommonButton
                id='reportRefreshBtn'
                text={"리포트 새로받기"}
                border={"#C9C9CA"}
                color={"#909195"}
                onClick={handleClickRefresh}
              />
              </ActionBox>
            </ContentBox>
          </SolidBox>
      }
      <FileShareDrawer open={shareOpen} onClose={toggleDrawerShare(false)} reportRef={reportRef} setChartView={setChartView}/>
    </Root>
  );
}

export default inject('reportStore')(observer(OverallReviewComponent));
