import React from "react";

import { Avatar, Box, Pagination, PaginationItem } from "@mui/material";

import { ReactComponent as ArrowBackIcon } from "../../../common/Icons/ArrowBackIcon.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../common/Icons/ArrowForwardIcon.svg";
import AvatarPokoro from "../../../common/Images/AvatarPokoro.svg";
import {
  BadgeStyle,
  CardBoxStyle,
  DateTextStyle,
  ListContent,
  ListTitle,
  PaginationStyle,
  Root,
  TitleBox,
} from "./Styled/ConversationContentComponentStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

function ConversationContentComponent(props) {
  const {authStore, userStore, conversationStore} = props;

  const getDate = (datetime, duration) => {
      const now = dayjs(dayjs().format('YYYY-MM-DD'));
      const startDatetime = dayjs(datetime);
      const endDatetime = startDatetime.add(duration, 'second');
      const endDate = dayjs(endDatetime.format('YYYY-MM-DD'));
      const dayDiff = now.diff(endDate, 'day');

      if (dayDiff === 0) {
          return endDatetime.format('H:mm')
      } else if (dayDiff === 1) {
          return '어제';
      } else {
          return endDatetime.format('M월 D일');
      }
  }

  const handleChangePage = (e, newPage) => {
      conversationStore.setPage(newPage);
      conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId);
  }

  const handleClickDetail = (dialogId) => {
      conversationStore.setType('detail');
      conversationStore.getConversation(dialogId);
  }

  return (
    <Root>
      {conversationStore.conversationList.map((conversation, index) => (
        <CardBoxStyle key={index} onClick={() => handleClickDetail(conversation.id)}>
          <BadgeStyle variant={conversation.check === false ? "dot" : ""}>
            <Avatar src={AvatarPokoro}></Avatar>
          </BadgeStyle>
          <Box style={{width: '100%'}}>
            <TitleBox>
              <ListTitle>{conversation.firstMessage}</ListTitle>
              <DateTextStyle>{getDate(conversation.startDatetime, conversation.duration)}</DateTextStyle>
            </TitleBox>
            <ListContent>{conversation.lastMessage}</ListContent>
          </Box>
        </CardBoxStyle>
      ))}
      <PaginationStyle>
        <Pagination
          count={Math.ceil(conversationStore.conversationCount / conversationStore.rowsPerPage)}
          defaultPage={conversationStore.page}
          siblingCount={0}
          onChange={handleChangePage}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: ArrowBackIcon,
                next: ArrowForwardIcon,
              }}
              {...item}
            />
          )}
        />
      </PaginationStyle>
    </Root>
  );
}

export default inject('authStore', 'userStore', 'conversationStore')(observer(ConversationContentComponent));