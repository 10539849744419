import {makeAutoObservable} from "mobx";

export const State = {
    Authenticated: 'Authenticated',
    NotAuthenticated: 'NotAuthenticated',
    Pending: 'Pending',
    Failed: 'Failed',
};

export const LocalStorageTokenKey = '_AUTHENTICATION_TOKEN_';
export const LocalStorageLoginType = '_AUTHENTICATION_LOGIN_TYPE_';

const EmptyLogin = {
    loginType: '',
    code: ''
};

const EmptyUser = {
    id: '',
    nickname: '',
    allowTermsOfUse: false,
    allowTermsOfPrivacy: false,
    isEnabled: false,
    loginType: '',
    profileImageUrl: '',
    createdDatetime: '',
    updatedDatetime: '',
};

const logPrefix  = "[AuthStore]"
export default class AuthStore {
    constructor(props) {
        this.authRepository = props.authRepository;

        this.loginType = '';
        this.loginCode = '';
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);

        this.termsOfAge = false;
        this.termsOfUse = false;
        this.termsOfPrivacy = false;
        this.nickname = '';

        makeAutoObservable(this);
    }


    invalidateLogin = () => {
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);
    };

    setLoginType = (type) => {
        this.loginType = type;
    }

    setLoginCode = (code) => {
        this.loginCode = code;
    }

    setTermsOfAll = (agree) => {
        this.termsOfAge = agree;
        this.termsOfUse = agree;
        this.termsOfPrivacy = agree;
    }

    setTermsOfAge = (agree) => {
        this.termsOfAge = agree;
    }

    setTermsOfUse = (agree) => {
        this.termsOfUse = agree;
    }

    setTermsOfPrivacy = (agree) => {
        this.termsOfPrivacy = agree;
    }

    setNickname = (nickname) => {
        if (nickname.length <= 8) {
            this.nickname = nickname;
        }
    }

    setLoginState = (state) => {
        this.loginState = state;
    }

    isNotAcceptableNickname = () => {
        if (this.nickname !== '') {
            const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
            if (specialCharactersRegex.test(this.nickname)) {
                return "특수문자는 입력이 불가합니다.";
            }

            const incompleteHangulRegex = /[ㄱ-ㅎㅏ-ㅣ]/;
            if (incompleteHangulRegex.test(this.nickname)) {
                return "정확한 단어를 입력해주세요.";
            }

            if (this.nickname.length < 2) {
                return "글자수가 부족합니다.";
            }
        }

        return '';
    }

    * doLogin(history, callback) {
        this.loginState = State.Pending;

        try {
            console.log(logPrefix, 'Start doLogin.');

            this.login.loginType = this.loginType;
            this.login.code = this.loginCode;
            const param = this.login;
            const response = yield this.authRepository.login(param);

            const token = response.data.token;
            const user = response.data.user;

            localStorage.setItem(LocalStorageTokenKey, token);
            localStorage.setItem(LocalStorageLoginType, this.loginType);

            console.log('doLogin');
            console.log(this);

            this.loginState = State.Authenticated;
            this.loginToken = token;
            this.loginUser = user;
            this.loginCode = '';

            console.log(logPrefix, 'Finished doLogin.');

            const agreeTerms = this.loginUser.allowTermsOfUse === true && this.loginUser.allowTermsOfPrivacy === true;
            if (!agreeTerms) {
                history.push('/signin/terms');
            } else if (this.loginUser.nickname === null) {
                history.push('/signin/nickname');
            } else {
                history.push('/home');
            }

            callback && callback();
        } catch (e) {
            console.log(logPrefix, 'Failed doLogin.');
            this.loginState = State.Failed;
            this.loginToken = '';
            this.loginCode = '';
            this.loginUser = Object.assign({}, EmptyUser);
        }
    }

    * checkLogin() {
        const token = localStorage.getItem(LocalStorageTokenKey);

        if(token) {
            try {
                const response = yield this.authRepository.checkLogin();

                const user = response.data;
                this.loginState = State.Authenticated;
                this.loginUser = user;
            } catch(e) {
                this.loginState = State.NotAuthenticated;
                this.loginToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
            }
        }
    }

    * doLogout(callback) {
        try {
            yield this.authRepository.logout();

            console.log(this);
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);

            callback && callback();
        } catch(e) {
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        } finally {
            localStorage.removeItem(LocalStorageTokenKey);
        }
    }

    * agreeTerms(history) {
        try {
            console.log(logPrefix, 'Start agreeTerms.');

            const param = {userId: this.loginUser.id, isAllowTermsOfUse: this.termsOfUse, isAllowTermsOfPrivacy: this.termsOfPrivacy, loginType: this.loginUser.loginType, profileImageUrl: this.loginUser.profileImageUrl}
            yield this.authRepository.agreeTerms(this.loginUser.id, param);

            console.log(logPrefix, 'Finished agreeTerms.');

            this.checkLogin();

            if (this.loginUser.nickname === null) {
                history.push('/signin/nickname');
            } else {
                history.push('/home');
            }

        } catch(e) {
            console.log(logPrefix, 'Failed agreeTerms. error: ' + e);
        }
    }

    * updateNickname(history) {
        try {
            console.log(logPrefix, 'Start updateNickname.');

            const param = {userId: this.loginUser.id, nickname: this.nickname, loginType: this.loginUser.loginType, profileImageUrl: this.loginUser.profileImageUrl}
            yield this.authRepository.updateNickname(this.loginUser.id, param);

            console.log(logPrefix, 'Finished updateNickname.');

            this.checkLogin();
            history.push('/home');

        } catch(e) {
            console.log(logPrefix, 'Failed updateNickname. error: ' + e);
        }
    }
}
