import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../common/Styled/CommonStyled";

export const Root = styled(Box)(() => ({
  padding: "60px 16px",
  backgroundColor: "#fff",
}));

export const TitleButton = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
export const BoxStyle = styled(CardBox)(() => ({
  display: "flex",
  flexDirection: "column",
  "& > Button": {
    display: "flex",
    alignItem: "center",
    justifyContent: "flex-start",
    borderTop: "1px solid #F0F1F5",
    marginTop: 16,
    paddingTop: 16,
    '& img':{
      width:48,
      height:48,
    },
    "&:first-of-type": {
      borderTop: "0 none",
      marginTop: 0,
      paddingTop: 0,
    },
    "&:hover": {
      background: "transparent",
    },
  },
}));

export const BannerInfo = styled(Box)(() => ({
  marginLeft: 16,
  "& .MuiTypography-root": {
    textAlign: "left",
  },
}));
