import React from "react";

import {Typography} from "@mui/material";

import {ReactComponent as ConnectAppIcon} from "../../common/Images/ConnectAppIcon.svg";
import {ReactComponent as GuideIcon} from "../../common/Images/GuideIcon.svg";
import PoKoRoShyImage from "../../common/Images/PoKoRoShyImage.png";
import {ReactComponent as ServiceCenterIcon} from "../../common/Images/ServiceCenterIcon.svg";
import {ReactComponent as ShieldIcon} from "../../common/Images/ShieldIcon.svg";
import {ReactComponent as TermsOfUseIcon} from "../../common/Images/TermsOfUseIcon.svg";
import UserDefault from "../../common/Images/UserDefault.png";
import CommonBack from "../CommonComponent/CommonBack";
import CommonButton from "../CommonComponent/CommonButton";
import {
  AppChipBox,
  ContentsBox,
  LineStyle,
  ListBox,
  ListButtonIn,
  ListButtonStyle,
  PoKoRoDateBox,
  PoKoRoDateText,
  Root,
  TitleText,
  UserBox,
  UserEmailText,
  UserImageBox,
  UserInfoBox,
  UserText,
} from "./Styled/SettingStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import {TermsOfPrivacyUrl, TermsOfUseUrl} from "../../stores/UserStore";
import {useHistory} from "react-router-dom";

export const AndroidPackageName = 'com.neolab_soundpen';
export const IosAppId = '%ED%8F%AC%EC%BD%94%EB%A1%9C-%EC%BB%A4%EB%84%A5%ED%8A%B8/id6477915377';

function Setting(props) {
  const {authStore, userStore} = props;
  const history = useHistory();

  const getDate = () => {
    const now = dayjs(dayjs().format('YYYY-MM-DD'));
    const firstDate = dayjs(dayjs(userStore.selectedDevice.firstUsedDatetime).format('YYYY-MM-DD'));

    return now.diff(firstDate, 'day');
  }

  const handleClickGuide = () => {
    window.open('https://spectrum-tank-5ab.notion.site/67da6484adaa47cb93096a99373ce518?pvs=4');
  }

  const handleClickServiceCenter = () => {
    window.open('https://neosmartpen.co.kr/customer');
  }

  const handleClickTermsOfUse = () => {
    window.open(TermsOfUseUrl);
  }

  const handleClickTermsOfPrivacy = () => {
    window.open(TermsOfPrivacyUrl);
  }

  const handleClickLogout = () => {
    authStore.doLogout(() => history.push('/'));
  }

  const handleClickMoveStore = () => {
    const agent = navigator.userAgent.toLowerCase();

    if (/(mac|iphone|ipod|ipad)/i.test(agent)) {
      window.open('https://apps.apple.com/kr/app/' + IosAppId, '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=' + AndroidPackageName, '_blank');
    }
  }

  const handleClickGoBack = () => {
    history.goBack();
  }

  return (
    <Root>
      <CommonBack onClick={handleClickGoBack} />
      <ContentsBox>
        <UserBox>
          <UserImageBox>
            {authStore.loginUser.profileImageUrl === null || authStore.loginUser.profileImageUrl === '' ?
                <img src={UserDefault} alt={"User"} />
                :
                <img style={{borderRadius: '50%'}} src={authStore.loginUser.profileImageUrl} alt={"User"} />
            }
          </UserImageBox>
          <UserInfoBox>
            <UserEmailText>{authStore.loginUser.id}</UserEmailText>
            <UserText>{authStore.loginUser.loginType}</UserText>
          </UserInfoBox>
        </UserBox>
        <PoKoRoDateBox>
          <PoKoRoDateText>
            포코로와 함께한 지<br />
            <span>{getDate()}일째</span> 입니다.
          </PoKoRoDateText>
          <img src={PoKoRoShyImage} alt={"포코로 이미지"} />
        </PoKoRoDateBox>

        <LineStyle />

        <ListBox>
          <TitleText>포코로 200% 활용하기</TitleText>
              <ListButtonStyle disableRipple>
                <ListButtonIn onClick={handleClickMoveStore}>
                  <ConnectAppIcon />
                  <Typography>포코로 커넥트 앱</Typography>
                </ListButtonIn>
                <ListButtonIn onClick={handleClickMoveStore}>
                  <AppChipBox>
                    <Typography>앱 다운로드 하기</Typography>
                  </AppChipBox>
                </ListButtonIn>
              </ListButtonStyle>
        </ListBox>

        <ListBox>
          <TitleText>포코로에 대해 궁금하신가요?</TitleText>
          <ListButtonStyle disableRipple>
            <ListButtonIn onClick={handleClickGuide}>
              <GuideIcon />
              <Typography>포코로 가이드</Typography>
            </ListButtonIn>
          </ListButtonStyle>
          <ListButtonStyle disableRipple>
            <ListButtonIn onClick={handleClickServiceCenter}>
              <ServiceCenterIcon />
              <Typography>고객센터</Typography>
            </ListButtonIn>
          </ListButtonStyle>
        </ListBox>

        <ListBox>
          <TitleText>서비스 약관</TitleText>
          <ListButtonStyle disableRipple>
            <ListButtonIn onClick={handleClickTermsOfUse}>
              <TermsOfUseIcon />
              <Typography>이용약관</Typography>
            </ListButtonIn>
          </ListButtonStyle>
          <ListButtonStyle disableRipple>
            <ListButtonIn onClick={handleClickTermsOfPrivacy}>
              <ShieldIcon />
              <Typography>개인정보보호 약관</Typography>
            </ListButtonIn>
          </ListButtonStyle>
        </ListBox>

        <CommonButton
          text={"로그아웃"}
          background={"#fff"}
          border={"#C9C9CA"}
          color={"#909195"}
          onClick={handleClickLogout}
        />

        {/*<CommonButton text={"계정삭제"} background={"#fff"} color={"#909195"} />*/}
      </ContentsBox>
    </Root>
  );
}

export default inject('authStore', 'userStore')(observer(Setting));
