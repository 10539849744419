import { Repository } from './Repository';

export default class UserRepository extends Repository {
    constructor() {
        super();

        this.userRequestPrefix = '/api/v1/user';
    }

    getUserDeviceList = (userId) => {
        return this.getRequestPromise('get', this.userRequestPrefix + `/${userId}/device`);
    };

    getUserWeekList = (userId, deviceId) => {
        return this.getRequestPromise('get', this.userRequestPrefix + `/${userId}/week?deviceId=${deviceId}`);
    };

    getUserDashboard = (url) => {
        return this.getRequestPromise('get', this.userRequestPrefix + `/${url}`);
    };
}
