import { styled } from "@mui/material/styles";
import { Box, FormControlLabel, IconButton } from "@mui/material";

export const Root = styled(Box)(() => ({
  width: "100%",
}));

export const FormControlLabelBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: 30,
}));

export const FormControlLabelBoxIn = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px 6px 8px 16px",
  boxSizing: "border-box",
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  "&.MuiButtonBase-root": {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
}));

export const FormControlLabelStyle = styled(FormControlLabel)(() => ({
  "&.MuiFormControlLabel-root": {
    padding: 0,
    boxSizing: "border-box",
    margin: 0,
    "& .MuiButtonBase-root": {
      padding: 0,
      marginRight: 8,
    },
    "& .MuiTypography-root": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "19.6px",
      color: "#212121",
      "& span": {
        fontSize: "0.75rem",
        color: "#fe536b",
        lineHeight: "16.8px",
      },
    },
  },
}));

export const AllFormControlLabelStyle = styled(FormControlLabelStyle)(() => ({
  "&.MuiFormControlLabel-root": {
    width: "100%",
    border: "1px solid #F0F1F5",
    margin: "0 0 8px 0",
    borderRadius: 8,
    padding: 16,
    "& .MuiTypography-root": {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "22.4px",
      color: "#212121",
    },
  },
}));
