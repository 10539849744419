import React from "react";

import { Box } from "@mui/material";

import CommonBack from "../CommonComponent/CommonBack";
import CommonButton from "../CommonComponent/CommonButton";
import CheckedComponent from "./CheckedComponent";
import SignInText from "./SignInText";
import { InfoBox, SignInContentsBox, SignInRoot } from "./Styled/SignInStyled";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import CommonDialog from "../CommonComponent/CommonDialog";

function TermsOfUse(props) {
    const {authStore} = props;
    const history = useHistory();

    const handleClick = () => {
        authStore.agreeTerms(history);
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const moveLoginPage = () => {
        authStore.doLogout(() => history.push('/'));
    }

  return (
    <SignInRoot>
      <CommonBack onClick={handleClickOpen} />

      <SignInContentsBox>
        <InfoBox>
          <SignInText
            title={"서비스 이용약관"}
            text={
              <span>
                해당 서비스를 이용하려면
                <br />
                약관의 동의가 필요해요.
              </span>
            }
          />
        </InfoBox>

        <Box>
          <CheckedComponent />
          <CommonButton text={"확인"} background={"#FDD751"} color={"#333"} disabled={!(authStore.termsOfAge && authStore.termsOfUse && authStore.termsOfPrivacy)} onClick={handleClick}/>
        </Box>
      </SignInContentsBox>

        <CommonDialog
            open={open}
            title={"로그인 페이지로 이동하시겠습니까?"}
            subText={
                <span>
            현재 로그인된 상태입니다.<br />
            이동할 경우 자동 로그아웃 처리됩니다.
            <br />
            그래도 이동하시겠습니까?
          </span>
            }
            onClick1={moveLoginPage}
            onClick2={handleClickClose}
            LeftButtonText={"예"}
            RightButtonText={"아니오"}
        />
    </SignInRoot>
  );
}

export default inject('authStore')(observer(TermsOfUse));
