import React from "react";

import {Box, Typography} from "@mui/material";

import {DrawerStyle, Puller} from "./Styled/FilterDrawerStyled";
import CommonButton from "../../CommonComponent/CommonButton"
import {styled} from "@mui/material/styles";
import html2canvas from "html2canvas";
import {inject, observer} from "mobx-react";
import jsPDF from "jspdf";

const ActionBox = styled(Box)(() => ({
    display:'flex',
    flexDirection : 'column',
    gap:4,
    marginTop:32,
}));


function FileShareDrawer(props) {
    const {reportStore} = props;
    const {open, onClose, reportRef, setChartView} = props;

    let isPdfDown = false;
    const userAgent = navigator.userAgent.toLowerCase();
    if (!userAgent.includes('samsung')) {
        if (userAgent.includes('safari') || userAgent.includes('chrome')) {
            isPdfDown = true;
        }
    }

    const handleClickShare = async (fileType) => {
        reportStore.setShareData('');
        reportStore.setIsReportDownloadLoading(true);
        onClose();

        const prevChartView = props.chartView;
        if (!props.chartView) {
            setChartView(true);
        }

        const reportTitle = `${((String)(reportStore.report.year)).slice(-2)}년 ${reportStore.report.month}월 ${reportStore.report.week}주차 리포트`;
        reportStore.setShareFileTitle(`${reportTitle}.${fileType}`);

        let shareData = await generateImage(reportTitle, fileType);
        reportStore.setShareData(shareData);

        setChartView(prevChartView);
        reportStore.setIsReportDownloadLoading(false);

        reportStore.setShareDialogOpen(true);
    }

    const generateImage = async (reportName, fileType) => {
        const report = reportRef.current;
        if (!report) return;

        const cLoseBtnEl = document.getElementById('reportCLoseBtn');
        const chartViewBtnEl = document.getElementById('charViewBtn');
        const shareBtnEl = document.getElementById('reportShareBtn');
        const refreshBtnEl = document.getElementById('reportRefreshBtn');

        try {
            if (cLoseBtnEl) {
                cLoseBtnEl.style.display = 'none';
            }
            if (chartViewBtnEl) {
                chartViewBtnEl.style.display = 'none';
            }
            if (shareBtnEl) {
                shareBtnEl.style.display = 'none';
            }
            if (refreshBtnEl) {
                refreshBtnEl.style.display = 'none';
            }

            const canvas = await html2canvas(report, { scrollY: 0 });
            const imageData = cropCanvas(canvas, 0, 0, canvas.width, canvas.height, fileType === 'pdf' ? 'jpeg' : fileType);

            let shareData = {title: `${reportName}.${fileType}`, thumbnail: imageData};

            if (fileType !== 'pdf') {
                const blob = await fetch(imageData).then(res => res.blob());
                shareData.files = [new File([blob], `${reportName}.${fileType}`, {type: `image/${fileType}`})];
            } else {
                const imgWidth = 210;
                const pageHeight = imgWidth * 1.414;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                const doc = new jsPDF('p', 'mm');
                let position = 0;

                doc.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 20) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                const blob = doc.output('blob');
                shareData.files = [new File([blob], `${reportName}.pdf`, {type: 'application/pdf'})];
            }

            if (cLoseBtnEl) {
                cLoseBtnEl.style.display = 'inline-flex';
            }
            if (chartViewBtnEl) {
                chartViewBtnEl.style.display = 'inline-flex';
            }
            if (shareBtnEl) {
                shareBtnEl.style.display = 'inline-flex';
            }
            if (refreshBtnEl) {
                refreshBtnEl.style.display = 'inline-flex';
            }

            return shareData;
        } catch (error) {
            console.error('GenerateImage Error:', error);
        } finally {
            if (cLoseBtnEl) {
                cLoseBtnEl.style.display = 'inline-flex';
            }
            if (chartViewBtnEl) {
                chartViewBtnEl.style.display = 'inline-flex';
            }
            if (shareBtnEl) {
                shareBtnEl.style.display = 'inline-flex';
            }
            if (refreshBtnEl) {
                refreshBtnEl.style.display = 'inline-flex';
            }
        }
    };

    const cropCanvas = (sourceCanvas, startX, startY, width, height, fileType) => {
        const targetCanvas = document.createElement('canvas');
        targetCanvas.width = width;
        targetCanvas.height = height;
        const context = targetCanvas.getContext('2d');
        context.drawImage(sourceCanvas, startX, startY, width, height, 0, 0, width, height);
        return targetCanvas.toDataURL(`image/${fileType}`);
    };

  return (
      <DrawerStyle
          anchor="bottom"
          open={open}
          onClose={onClose}
          style={{zIndex:9999}}
      >
          <Puller />

          <Typography variant='h5'>공유할 파일 유형 선택</Typography>
          <Typography variant='h6'>내보내기 및 공유할 리포트의 유형을 선택해주세요.</Typography>
          <ActionBox>
              <CommonButton
                  // disabled={'disabled'}
                  border={'#C9C9CA'}
                  background={'#fff'}
                  color={'#333'}
                  text={'JPG'}
                  onClick={() => handleClickShare('jpeg')}
              />
              <CommonButton
                  // disabled={'disabled'}
                  border={'#C9C9CA'}
                  background={'#fff'}
                  color={'#333'}
                  text={'PNG'}
                  onClick={() => handleClickShare('png')}
              />
              {isPdfDown &&
                  <CommonButton
                      // disabled={'disabled'}
                      border={'#C9C9CA'}
                      background={'#fff'}
                      color={'#333'}
                      text={'PDF'}
                      onClick={() => handleClickShare('pdf')}
                  />
              }
          </ActionBox>
      </DrawerStyle>
  );
}

export default inject('reportStore')(observer(FileShareDrawer));