import React from 'react';
import Picker from 'react-mobile-picker';


import {DrawerDateStyle} from "./Styled/FilterDrawerStyled";
import CommonButton from "../../CommonComponent/CommonButton";
import {inject, observer} from "mobx-react";
import _ from "lodash";


function FilterDatePicker(props) {
    const {userStore, reportStore} = props;
    const {setOpenDialog} = props;

    const {open, onClose} = props;

    const getYearList = () => {
        let yearList = userStore.userWeekOfMonthList.map(week => week.year);
        yearList.sort((a, b) => b - a);

        const maxYear = yearList[0];
        const minYear = yearList[yearList.length - 1];
        const result = [];
        for (let i = minYear; i <= maxYear; i++) {
            result.push(i);
        }

        return result;
    }

    const selections = {
        month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        week: [0, 1, 2, 3, 4, 5]
    }

    const handleClick = () => {
        if (reportStore.selectedDirectSearchType === 'start') {
            let startYear = reportStore.startPickerDate.year;
            const startMonth = reportStore.startPickerDate.month;
            const startWeek = reportStore.startPickerDate.week;

            const endYear = reportStore.directSearchEndDate.year;
            const endMonth = reportStore.directSearchEndDate.month;
            const endWeek = reportStore.directSearchEndDate.week;

            if (startYear === 0) {
                const newStartPickerDate = _.cloneDeep(reportStore.startPickerDate);
                newStartPickerDate.year = getYearList()[0];
                reportStore.setStartPickerDate(newStartPickerDate);

                startYear = newStartPickerDate.year;
            }

            reportStore.setDirectSearchStartDate(reportStore.startPickerDate);

            if (endYear !== 0) {
                let isEndDateChange = false;
                if (startYear > endYear) {
                    isEndDateChange = true;
                } else if (startMonth !== 0 && endMonth === 0) {
                    isEndDateChange = true;
                } else if (startWeek !== 0 && endWeek === 0) {
                    isEndDateChange = true;
                } else if (startMonth === 0 && endMonth !== 0) {
                    isEndDateChange = true;
                } else if (startWeek === 0 && endWeek !== 0) {
                    isEndDateChange = true;
                } else if (startMonth > endMonth) {
                    isEndDateChange = true;
                } else if (startWeek > endWeek) {
                    isEndDateChange = true;
                }

                if (isEndDateChange) {
                    reportStore.setEndPickerDate(reportStore.startPickerDate);
                    reportStore.setDirectSearchEndDate(reportStore.startPickerDate);
                }
            }

            onClose();
        } else {
            const startYear = reportStore.directSearchStartDate.year;
            const startMonth = reportStore.directSearchStartDate.month;
            const startWeek = reportStore.directSearchStartDate.week;

            let endYear = reportStore.endPickerDate.year;
            const endMonth = reportStore.endPickerDate.month;
            const endWeek = reportStore.endPickerDate.week;

            if (endYear === 0) {
                const newEndPickerDate = _.cloneDeep(reportStore.endPickerDate);
                newEndPickerDate.year = getYearList()[0];
                reportStore.setEndPickerDate(newEndPickerDate);

                endYear = newEndPickerDate.year;
            }

            reportStore.setDirectSearchEndDate(reportStore.endPickerDate);

            if (startYear !== 0) {
                let isOpenDialog = false;
                if (startYear > endYear) {
                    isOpenDialog = true;
                } else if (endMonth !== 0 && startMonth === 0) {
                    isOpenDialog = true;
                } else if (endWeek !== 0 && startWeek === 0) {
                    isOpenDialog = true;
                } else if (startMonth > endMonth) {
                    isOpenDialog = true;
                } else if (startWeek > endWeek) {
                    isOpenDialog = true;
                }

                if (isOpenDialog) {
                    setOpenDialog(true);
                    return;
                }
            }

            onClose();
        }
    }

  return (
      <DrawerDateStyle
          anchor="bottom"
          open={open}
          onClose={onClose}
      >
          <Picker value={(reportStore.selectedDirectSearchType === 'start') ? reportStore.startPickerDate : reportStore.endPickerDate}
                  onChange={reportStore.selectedDirectSearchType === 'start' ? reportStore.setStartPickerDate : reportStore.setEndPickerDate}
                  height={106}
                  style={{marginBottom:32}}>
              <Picker.Column name='year' className='pickerColumn'>
                  {getYearList().map(year => (
                      <Picker.Item key={year} value={year} className='pickerItem'>
                          {({ selected }) => (
                              <div style={{ color: selected ? '#333333' : '#909195' }}>
                                  {year}년
                              </div>
                          )}

                      </Picker.Item>
                  ))}
              </Picker.Column>
              {Object.keys(selections).map(name => (
                  <Picker.Column key={name} name={name}  className='pickerColumn'>
                      {selections[name].map(option => (
                          <Picker.Item key={option} value={option} className='pickerItem'>
                              {({ selected }) => (
                                  <div style={{ color: selected ? '#333333' : '#909195' }}>
                                      {option === 0 ? '전체'
                                          :
                                          <React.Fragment>
                                              {option}
                                              {name === 'month' ? '월' : '주차'}
                                          </React.Fragment>

                                      }
                                  </div>
                              )}

                          </Picker.Item>
                      ))}
                  </Picker.Column>
              ))}
          </Picker>
          <CommonButton
              disabled={(reportStore.selectedDirectSearchType === 'start') ? (reportStore.startPickerDate.month === 0 && reportStore.startPickerDate.week !== 0) : (reportStore.endPickerDate.month === 0 && reportStore.endPickerDate.week !== 0)}
              background={'#FDD751'}
              color={'#333'}
              text={'확인'}
              onClick={handleClick}
          />
      </DrawerDateStyle>
  );
}

export default inject('userStore', 'reportStore')(observer(FilterDatePicker));