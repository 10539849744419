import { Repository } from './Repository';

export default class ReportRepository extends Repository {
    constructor() {
        super();

        this.reportRequestPrefix = '/api/v1/report';
    }

    getReportList = (userId, deviceId, sortDirection, section, startYear, startMonth, startWeek, endYear, endMonth, endWeek) => {
        return this.getRequestPromise('get', this.reportRequestPrefix + `/list/?userId=${userId}&deviceId=${deviceId}&sortDirection=${sortDirection}&section=${section}&startYear=${startYear}&startMonth=${startMonth}&startWeek=${startWeek}&endYear=${endYear}&endMonth=${endMonth}&endWeek=${endWeek}`);
    };

    getReport = (userId, deviceId, year, month, week, isRenewal) => {
        return this.getRequestPromise('get', this.reportRequestPrefix + `/?userId=${userId}&deviceId=${deviceId}&year=${year}&month=${month}&week=${week}&isRenewal=${isRenewal}`);
    };

    updateReportDisplay = (param) => {
        return this.getRequestPromise('put', this.reportRequestPrefix + `/display`, param);
    }
}
