import React from "react";
import {
  SignInTitleTextStyle,
  SignInTextStyle,
  TextBox,
} from "./Styled/SignInStyled";

export default function SignInInfo(props) {
  const { title, text } = props;

  return (
    <TextBox>
      <SignInTitleTextStyle>{title}</SignInTitleTextStyle>
      <SignInTextStyle>{text}</SignInTextStyle>
    </TextBox>
  );
}
