import React from "react";

import { Chip } from "@mui/material";

import {
  ListCaption,
  ListTitle,
  SolidBox,
  TitleStyle,
} from "../../common/Styled/CommonStyled";
import {
  BoxTitle,
  ChipBox,
  Contents,
  ListBox,
  Root,
  ViewerButtonStyle,
} from "./Styled/ConversationComponentStyled";
import { MoreButton } from "./Styled/RankingComponentStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

function ConversationComponent(props) {
  const {authStore, userStore, conversationStore} = props;

  const uniqueWords = {};
  userStore.userDashboardData.recentDialogList.forEach(dialog => {
    dialog.topicWordList.forEach(word => {
      uniqueWords[word] = true;
    });
  });

  const uniqueWordList = Object.keys(uniqueWords);
  const uniqueWordColorList = [];
  let colorList = ['#305580', '#B79470', '#FCB100', '#9277BF', '#5CBA47', '#DE6F9E', '#DE765F', '#90CBFB', '#FEA443'];

  for (const uniqueWord of uniqueWordList) {
    const randomIndex = Math.floor(Math.random() * colorList.length);
    const randomColor = colorList[randomIndex];

    uniqueWordColorList.push({word: uniqueWord, color: randomColor});
    colorList = colorList.filter((_, index) => index !== randomIndex);
  }

  function getColorByWord(word) {
    for (let i = 0; i < uniqueWordColorList.length; i++) {
      if (uniqueWordColorList[i].word === word) {
        return uniqueWordColorList[i].color;
      }
    }
  }

  const getDate = (dialogStartDatetime) => {
    return dayjs(dialogStartDatetime).format('YYYY.MM.DD A hh:mm').replace('AM', '오전').replace('PM', '오후');
  }

  const handleClickDialogDetail = (dialog) => {
    conversationStore.init();
    conversationStore.setType('detail');
    conversationStore.getConversation(dialog.dialogId);
    props.handleChangeTab(null, 'conversation');
  }

  const handleClickMoveConversation = () => {
    conversationStore.init();
    props.handleChangeTab(null, 'conversation');
  }

  return (
    <Root>
      {userStore.userDashboardData.recentDialogList.length === 0 ?
          <React.Fragment>
            <TitleStyle>최근 대화 내용</TitleStyle>
            <Contents>
              <SolidBox>아직 대화내용이 없습니다.</SolidBox>
            </Contents>
          </React.Fragment>
          :
          <React.Fragment>
            <TitleStyle>
              최근 {authStore.loginUser.nickname}(이)가
              <br />
              어떤 대화를 했을까요?
            </TitleStyle>
            <Contents>
              {userStore.userDashboardData.recentDialogList.map((dialog, index) => (
                  <SolidBox key={index}>
                    <ChipBox>
                      {dialog.topicWordList.map((word, wordIndex) => (
                          <Chip key={wordIndex} label={word} style={{ backgroundColor: getColorByWord(word) }} />
                      ))}
                    </ChipBox>
                    <BoxTitle>
                      <ListTitle variant="subtitle2">
                        {dialog.firstMessage}
                      </ListTitle>
                      <ListCaption>{getDate(dialog.startDatetime)}</ListCaption>
                    </BoxTitle>
                    <ListBox>
                      <ul>
                        {dialog.summaryList.map((summary, summaryIndex) => (
                            <li key={summaryIndex}>{summary}</li>
                        ))}
                      </ul>
                    </ListBox>
                    <MoreButton disableRipple onClick={() => handleClickDialogDetail(dialog)}>더보기</MoreButton>
                  </SolidBox>
              ))}
            </Contents>
            <ViewerButtonStyle onClick={handleClickMoveConversation}>전체 대화 보기</ViewerButtonStyle>
          </React.Fragment>

      }
    </Root>
  );
}

export default inject('authStore', 'userStore', 'conversationStore')(observer(ConversationComponent));
