import React from "react";
import Slider from "react-slick";

import { Box, Typography } from "@mui/material";

import SadPokoro from "../../common/Images/SadPokoro.png";
import { ReactComponent as TrophyBronze} from "../../common/Icons/TrophyBronze.svg";
import { ReactComponent as TrophyGold} from "../../common/Icons/TrophyGold.svg";
import { ReactComponent as TrophySilver} from "../../common/Icons/TrophySilver.svg";
import SelectDateComponent from "./SelectDateComponent";
import {
  EmptyBox,
  LatestStyle,
  MoreButton,
  Root,
  SliderWrap,
  SlideTitle,
  UlStyle,
} from "./Styled/RankingComponentStyled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

function RankingComponent(props) {
  const {userStore, conversationStore} = props;

  const getEmptyBox = () => {
    return (
        <EmptyBox>
          <img src={SadPokoro} alt={"SadPokoro"} />
          <Typography>
            아직 포코로에 쌓인 대화가 부족합니다.
            <br /> 충분한 대화를 한 뒤 다시 접속해주세요 :)
          </Typography>
        </EmptyBox>
    )
  }

  const getMessage = (message, word) => {
    const highlightStartIndexList = [];
    const highlightEndIndexList = [];

    const isEnglish = /^[a-zA-Z]+$/.test(word);

    if (isEnglish) {
      const lowercaseMessage = message.toLowerCase();
      const lowercaseWord = word.toLowerCase();

      const regexPattern = `(?<![a-zA-Z])${lowercaseWord}(?![a-zA-Z])`;
      const regex = new RegExp(regexPattern, 'g');
      let match;

      while ((match = regex.exec(lowercaseMessage)) !== null) {
        highlightStartIndexList.push(match.index);
        highlightEndIndexList.push((match.index + lowercaseWord.length - 1));
      }
    } else {
      let startIndex = 0;
      while (true) {
        const index = message.indexOf(word, startIndex);
        if (index === -1) {
          break;
        }
        const endIndex = index + word.length - 1;

        highlightStartIndexList.push(index);
        highlightEndIndexList.push(endIndex);

        startIndex = index + 1;
      }
    }

    let messageResult = '<div>';

    for (let i = 0; i < message.length; i++) {
      if (highlightStartIndexList.includes(i) && highlightEndIndexList.includes(i)) {
        messageResult = messageResult + `<b>` + message[i] + '</b>';
      } else if (highlightStartIndexList.includes(i)) {
        messageResult = messageResult + `<b>` + message[i];
      } else if (highlightEndIndexList.includes(i)) {
        messageResult = messageResult + message[i] + `</b>`;
      } else {
        messageResult = messageResult + message[i];
      }
    }
    messageResult = messageResult + '</div>';

    return <div dangerouslySetInnerHTML={{ __html: messageResult }} />;
  }

  const getDate = (dialogStartDatetime) => {
    return dayjs(dialogStartDatetime).format('YYYY.MM.DD A hh시 mm분').replace('AM', '오전').replace('PM', '오후');
  }

  const handleClickDialogDetail = (dialog) => {
    conversationStore.init();
    conversationStore.setType('detail');
    conversationStore.setTopScrollMessage(dialog.message);
    conversationStore.getConversation(dialog.dialogId);
    props.handleChangeTab(null, 'conversation');
  }

  const handleClickMoveConversation = (word) => {
    conversationStore.init();
    conversationStore.setTempSearchKeyword(word);
    conversationStore.setSearchKeyword(word);
    props.handleChangeTab(null, 'conversation');
  }
  
  return (
    <Root>
      <SelectDateComponent />
          {userStore.userDashboardData.rankingWordList.length === 0 ?
              getEmptyBox()
              :
              <SliderWrap>
                {userStore.userDashboardData.rankingWordList.length === 1 ?
                    <Box>
                      <SlideTitle>
                        <TrophyGold/>
                        <span>{userStore.userDashboardData.rankingWordList[0].ranking}위</span>
                        <p>{userStore.userDashboardData.rankingWordList[0].word}</p>
                      </SlideTitle>
                      <UlStyle>
                        <li>{userStore.userDashboardData.rankingWordList[0].word} 와 연관된 대화 횟수 : {userStore.userDashboardData.rankingWordList[0].dialogCount}회</li>
                        <li>{userStore.userDashboardData.rankingWordList[0].word} 와 연관된 대화의 총 시간 : {Math.round(userStore.userDashboardData.rankingWordList[0].dialogTime / 60)}분</li>
                      </UlStyle>
                      <LatestStyle>
                        {userStore.userDashboardData.rankingWordList[0].dialogList.map((dialog, index) => (
                            <Box key={index} onClick={() => handleClickDialogDetail(dialog)}>
                              <Typography variant="subtitle2">{getMessage(dialog.message, userStore.userDashboardData.rankingWordList[0].word)}</Typography>
                              <Typography variant="body2">{getDate(dialog.dialogStartDatetime)}</Typography>
                            </Box>
                        ))}
                      </LatestStyle>
                      <MoreButton disableRipple onClick={() => handleClickMoveConversation(userStore.userDashboardData.rankingWordList[0].word)}>더보기</MoreButton>
                    </Box>
                  :
                    <Slider
                        dots={true}
                        infinite={true}
                        arrows={false}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                    >
                      {userStore.userDashboardData.rankingWordList.map((rankingWord, index) => (
                          <Box key={index}>
                            <SlideTitle>
                              {rankingWord.ranking === 1 &&
                                  <TrophyGold/>
                              }
                              {rankingWord.ranking === 2 &&
                                  <TrophySilver/>
                              }
                              {rankingWord.ranking === 3 &&
                                  <TrophyBronze/>
                              }
                              <span>{rankingWord.ranking}위</span>
                              <p>{rankingWord.word}</p>
                            </SlideTitle>
                            <UlStyle>
                              <li>{rankingWord.word} 와 연관된 대화 횟수 : {rankingWord.dialogCount}회</li>
                              <li>{rankingWord.word} 와 연관된 대화의 총 시간 : {Math.round(rankingWord.dialogTime / 60)}분</li>
                            </UlStyle>
                            <LatestStyle>
                              {rankingWord.dialogList.map((dialog, index) => (
                                  <Box key={index} onClick={() => handleClickDialogDetail(dialog)}>
                                    <Typography variant="subtitle2">{getMessage(dialog.message, rankingWord.word)}</Typography>
                                    <Typography variant="body2">{getDate(dialog.dialogStartDatetime)}</Typography>
                                  </Box>
                              ))}
                            </LatestStyle>
                            <MoreButton disableRipple onClick={() => handleClickMoveConversation(rankingWord.word)}>더보기</MoreButton>
                          </Box>
                      ))}
                    </Slider>
                }
              </SliderWrap>
          }
    </Root>
  );
}

export default inject('userStore', 'conversationStore')(observer(RankingComponent));