import {
  Avatar,
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#FDD751",
  padding: 16,
  boxSizing: "border-box",
  zIndex: 1300,
  "& > div": {
    display: "flex",
    alignItems: "center",
  },
}));

export const AvatarStyle = styled(Avatar)(() => ({
  width: 30,
  height: 30,
  marginRight: 4,
}));
export const SelectButton = styled(Button)(() => ({
  color: "#333",
  fontSize: "0.75rem",
  "& > svg": {
    marginLeft: 16,
  },
}));

export const InfoBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
  "& .MuiBadge-badge": {
    backgroundColor: "#FE536B",
    top: 4,
    right: 6,
  },
  "& .MuiIconButton-root": {
    padding: 0,
  },
}));

export const UserImageIconButton = styled(IconButton)(() => ({
  "&.MuiButtonBase-root": {
    width: 24,
    height: 24,
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
    "& img": {
      width: "100%",
      objectFit: "cover",
    },
  },
}));

export const DrawerStyle = styled(SwipeableDrawer)(() => ({
  "& .MuiDrawer-paper": {
    top: 69,
    padding: "24px 16px 16px",
    borderRadius: "0 0 30px 30px",
    backgroundColor: "#FFFCF0",
  },
}));

export const DrawerTitle = styled(Box)(() => ({
  "& .MuiTypography-root": {
    color: "#333",
  },
  "& .MuiTypography-subtitle1": {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
  },
}));

export const DrawerContents = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginTop: 16,
  marginBottom: 8,
}));
export const Caption = styled(Typography)(() => ({
  fontSize: "0.75rem",
  textAlign: "right",
  color: "#909195",
}));
export const IdBox = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 8,
  width: "100%",
  padding: "24px 16px",
  border: "2px solid #F0F1F5",
  borderRadius: 16,
  backgroundColor: "#fff",
  boxSizing: "border-box",
  "& .MuiAvatar-root": {
    width: 56,
    height: 56,
  },
  "&.selected": {
    backgroundColor: "#fff",
    borderColor: "#FDD751",
  },
  "&:hover, &:focus, &.selected": {
    backgroundColor: "#fff",
  },
}));
export const UserInfoBox = styled(Box)(() => ({
  textAlign: "left",
  "& .MuiTypography-subtitle2": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#333",
  },
  "& .MuiTypography-body2": {
    fontSize: "0.75rem",
    color: "#909195",
  },
}));

export const Puller = styled("div")(() => ({
  width: 32,
  height: 2,
  backgroundColor: "#909195",
  borderRadius: 20,
  margin: "24px auto 0",
}));
