import { IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledInput = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root": {
    width: "100%",
  },
  "& .MuiInputBase-root": {
    width: "100%",
    background: "#fff",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "& input.MuiInputBase-input": {
      padding: "16px",
      fontSize: "0.75rem",
      color: "#333",
      fontWeight: 400,
      lineHeight: "16.8px",
      "&::placeholder": {
        color: "#C9C9CA",
        opacity: 1,
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #909195",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #909195",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #909195",
      },
    },
    "&.Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #FE536B",
      },
    },
  },
}));

export const ErrorInputText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.625rem",
    fontWeight: 400,
    color: "#FE536B",
    lineHeight: "14px",
    textAlign: "left",
    marginTop: 3,
  },
}));

export const SearchIconButton = styled(IconButton)(() => ({
  "&.MuiIconButton-root": {
    padding: 0,
  },
}));
