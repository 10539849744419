import { BottomNavigation } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(BottomNavigation)(() => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100vw",
  height: 75,
  borderTop: "1px solid #F0F1F5",
  zIndex: 1000,
  '& .MuiBottomNavigationAction-root':{
    '& svg':{
      width:28,
      height:28,
    },
    "&:not(.Mui-selected)": {
      '& svg path':{
        fill:'#D9D9D9'
      },
    },
  },
  "& .MuiBottomNavigationAction-label": {
    marginTop: 3,
    fontSize: "0.75rem",
    color: "#333",
    opacity: 0.3,
    transition: "none",
    "&.Mui-selected": {
      fontSize: "0.75rem",
      color: "#333",
      opacity: 1,
    },
  },
}));
