import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DialogBox = styled(Dialog)(() => ({
  "& .MuiBackdrop-root": {
    background: "rgba(0, 0, 0, 0.8)",
  },
  "& .MuiPaper-root": {
    minWidth: 328,
    background: "#fff",
    padding: "17px 24px 32px",
    boxSizing: "border-box",
    borderRadius: 20,
  },
}));

export const TopBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 13,
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  "&.MuiButtonBase-root": {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
}));

export const TitleText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    color: "#333",
    fontWeight: 700,
    lineHeight: "25.2px",
    textAlign: "left",
  },
}));

export const TextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    color: "#333",
    fontWeight: 400,
    lineHeight: "19.6px",
    textAlign: "left",
    marginTop: 10,
    "& span": {
      fontWeight: 700,
    },
  },
}));

export const ImageBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 10,
  marginBottom: 25,
}));

export const ButtonStyle = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    minWidth: 136,
    padding: 16,
    borderRadius: 8,
    boxSizing: "border-box",
    background: "#FDD751",
    "&:hover": {
      background: "#FDD751",
    },
    "& p": {
      fontSize: "0.875rem",
      color: "#333",
      lineHeight: "19.6px",
      fontWeight: 400,
    },
  },
}));
