import React from "react";
import {
  ErrorText,
  LoadingBox,
} from "./Styled/DeviceDataDialogComponentStyled";
import "react-circular-progressbar/dist/styles.css";
import {Loader} from "../CommonComponent/Styled/CommonLoadingStyled";

export default function DeviceDataDialogComponent() {
  return (
    <div>
      <LoadingBox>
          <Loader style={{marginBottom:0}}>
              <svg className="spinner" viewBox="0 0 48 48">
                  <circle className="path" cx="24" cy="24" r="21.5" fill="none" strokeWidth="5"></circle>
              </svg>
          </Loader>
        {/*<CircularProgressbar*/}
        {/*  value={percentage}*/}
        {/*  text={`${percentage}%`}*/}
        {/*  strokeWidth={10}*/}
        {/*/>*/}
      </LoadingBox>
      <ErrorText><span role="img" aria-label="Warning emoji">⚠️</span> 데이터를 가져오는 동안 해당창을 종료하지 마세요</ErrorText>
    </div>
  );
}
