import React from "react";

import { Button } from "@mui/material";

import { ReactComponent as ArrowRight } from "../../common/Icons/ArrowRight.svg";
import Detective from "../../common/Images/Detective.png";
import Dreaming from "../../common/Images/Dreaming.png";
import {
  ListCaption,
  ListTitle,
  TitleStyle,
} from "../../common/Styled/CommonStyled";
import {
  BannerInfo,
  BoxStyle,
  Root,
  TitleButton,
} from "./Styled/ReportComponentStyled";
import {inject, observer} from "mobx-react";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
function ReportComponent(props) {
  const {authStore, userStore, reportStore} = props;

  const getInterestWordList = () => {
    if (userStore.userDashboardData.interestWordList.length === 0) {
      return '관심 단어가 없습니다.';
    } else {
      return userStore.userDashboardData.interestWordList.join(', ');
    }
  }

  const handleClickReportDetail = () => {
    if (userStore.userDashboardData.messageCount === 0) {
      handleClickOpen('대화 없음', '포코로에 쌓인 대화가 부족합니다.');
    } else if (!userStore.userDashboardData.reportDisplay) {
      handleClickOpen('삭제된 리포트', '이미 삭제된 리포트입니다.');
    } else {
      reportStore.init();
      reportStore.getReport(userStore.userDashboardData, () => reportStore.setIsDetailOpen(true))
      props.handleChangeTab(null, 'report');
    }
  }


  // CheckPb 삭제된 리포트
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [contents, setContents] = React.useState('');

  const handleClickOpen = (title, contents) => {
    setTitle(title);
    setContents(contents);
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };


  return (
    <Root>
      <TitleButton onClick={handleClickReportDetail}>
        <TitleStyle>{authStore.loginUser.nickname}의 성장 리포트</TitleStyle>
        <ArrowRight />
      </TitleButton>
      <BoxStyle>
        <Button disableRipple>
          <img src={Detective} alt="Detective" />
          <BannerInfo>
            <ListCaption>가장 관심을 많이 가진 단어</ListCaption>
            <ListTitle>
              {getInterestWordList()}
            </ListTitle>
          </BannerInfo>
        </Button>
        <Button disableRipple>
          <img src={Dreaming} alt="Dreaming" />
          <BannerInfo>
            <ListCaption>이번주 포코로 대화량</ListCaption>
            <ListTitle>
              {userStore.userDashboardData.messageCount.toLocaleString()}회 <span>(상위 {userStore.userDashboardData.topPercent}%)</span>
            </ListTitle>
          </BannerInfo>
        </Button>
      </BoxStyle>

      <CommonComfirmDialog
          open={open}
          title = {title}
          contents={contents}
          buttonText={'확인'}
          onClick={handleClickClose}
      />
    </Root>
  );
}

export default inject('authStore', 'userStore', 'reportStore')(observer(ReportComponent));
