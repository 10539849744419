import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";

import {withStyles} from "@material-ui/core/styles";
import axios from "axios";

import * as store from "./stores/AuthStore";

import Home from "./views/Home/Home";
import Setting from "./views/Setting/Setting";
import SignIn from "./views/SignIn/SignIn";
import SignInInfo from "./views/SignIn/SignInInfo";
import TermsOfUse from "./views/SignIn/TermsOfUse";
import CommonDialog from "./views/CommonComponent/CommonDialog";
import DeviceDataDialogComponent from "./views/Home/DeviceDataDialogComponent";

const style = () => ({
    root: {
        display: 'flex',
    }
});



class App extends React.Component {
    componentDidMount() {
        const {loadingStore} = this.props;
        const axiosRequestInterceptors = (config) => {
            const token = localStorage.getItem(store.LocalStorageTokenKey);

            if(token) {
                config.headers['X-Auth-Token'] = token;
            }

            return config;
        };

        const axiosRequestErrorHandler = (error) => {
            return Promise.reject(error);
        };

        const axiosResponseInterceptor = (response) => {
            if(response.status === 403) {
                this.props.authStore.invalidateLogin();
                loadingStore.setIsLoading(false);
            }

            return response;
        };

        const axiosResponseErrorHandler = (error) => {
            if((error.response) && (error.response.status === 403)) {
                this.props.authStore.invalidateLogin();
                loadingStore.setIsLoading(false);
            }

            return Promise.reject(error);
        };

        console.log("========== RGate App componentDidMount ==========");
        axios.interceptors.request.use(axiosRequestInterceptors, axiosRequestErrorHandler);
        axios.interceptors.response.use(axiosResponseInterceptor, axiosResponseErrorHandler);

        this.props.authStore.checkLogin();
    }

    setMobileOpen(mobileOpen) {
        this.setState({mobileOpen: mobileOpen});
    }

    render() {
        const { classes } = this.props;
        const { loginState, loginUser } = this.props.authStore;
        const { loadingStore } = this.props;

        const agreeTerms = loginUser.allowTermsOfUse === true && loginUser.allowTermsOfPrivacy === true;

        return (
            <div className={classes.root}>
                <Router>
                    <Route>
                        {loginState !== store.State.Authenticated &&
                            <React.Fragment>
                                <Switch>
                                    <Route exact path="/" component={SignIn} />
                                    <Route exact path="/signin" component={SignIn} />
                                    <Redirect to="/" />
                                </Switch>
                            </React.Fragment>
                        }
                        {loginState === store.State.Authenticated &&
                            <React.Fragment>
                                {!agreeTerms ?
                                    <Switch>
                                        <Route exact path="/" component={TermsOfUse} />
                                        <Route exact path="/signin/terms" component={TermsOfUse} />
                                    </Switch>
                                    :
                                    <React.Fragment>
                                        {loginUser.nickname === null ?
                                            <Switch>
                                                <Route exact path="/" component={SignInInfo} />
                                                <Route exact path="/signin/nickname" component={SignInInfo} />
                                            </Switch>
                                            :
                                            <Switch>
                                                <Route exact path="/" component={Home} />
                                                <Route exact path="/home" component={Home} />
                                                <Route exact path="/setting" component={Setting} />
                                                {/*<Route exact path="/splash" component={Splash} />*/}
                                            </Switch>

                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Route>
                </Router>
                <CommonDialog
                    open={loadingStore.isLoading}
                    title={"디바이스에서 데이터를 가져오는 중..."}
                    subText={"포코로와의 대화 내용을 가져오는 중입니다."}
                    subTextColor={"#909195"}
                    childrenBox={<DeviceDataDialogComponent />}
                    action={false}
                />
            </div>
        );
    }
};

export default withStyles(style)(withRouter(inject('authStore', 'loadingStore')(observer(App))));
