import React, {useEffect} from "react";

import { Chip, Typography } from "@mui/material";

import { ReactComponent as ArrowLeftIcon } from "../../../common/Images/ArrowLeftIcon.svg";
import { ListCaption, ListTitle } from "../../../common/Styled/CommonStyled";
import {
  AnswerBox,
  BoxTitle,
  ButtonBack,
  CardBoxViewer,
  ChatBox,
  ChipBox,
  ListBox,
  QuestionBox,
  Root,
  WrapBox,
} from "./Styled/ConversationContentDetailComponentStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import CommonLoading from "../../CommonComponent/CommonLoading";

function ConversationContentDetailComponent(props) {
  const {conversationStore} = props;

  useEffect(() => {
    const scrollToElement = () => {
      if (conversationStore.topScrollMessage !== '') {
        const messageElements = document.getElementsByTagName('span');
        for (let i = 0; i < messageElements.length; i++) {
          if (messageElements[i].innerText === conversationStore.topScrollMessage) {
            messageElements[i].scrollIntoView({ behavior: 'smooth' });
            conversationStore.setTopScrollMessage('');
            break;
          }
        }
      }
    }
    setTimeout(scrollToElement, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let colorList = ['#305580', '#B79470', '#FCB100', '#9277BF', '#5CBA47', '#DE6F9E', '#DE765F', '#90CBFB', '#FEA443'];

  const getColorByWord = (word) => {
    const randomIndex = Math.floor(Math.random() * colorList.length);
    const randomColor = colorList[randomIndex];

    colorList = colorList.filter((_, index) => index !== randomIndex);

    return randomColor;
  }

  const getDate = (dialogStartDatetime) => {
    return dayjs(dialogStartDatetime).format('YYYY.MM.DD A hh:mm').replace('AM', '오전').replace('PM', '오후');
  }

  const convertSecondsToTime = (seconds) => {
    if (seconds < 60) {
      return seconds + '초';
    }

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    // let remainingSeconds = Math.floor(seconds % 60);

    let timeString = '';

    if (hours > 0) {
      timeString += hours + '시간 ';
    }

    if (minutes > 0) {
      timeString += minutes + '분 ';
    }

    // if (remainingSeconds > 0) {
    //   timeString += remainingSeconds + '초';
    // }

    return timeString.trim();
  }

  const handleClickMoveList = () => {
    conversationStore.setType('list');
  }


  return (
    <Root>
      <ButtonBack disableRipple onClick={handleClickMoveList}>
        <ArrowLeftIcon />
        <Typography>목록으로</Typography>
      </ButtonBack>
      <React.Fragment>
        {conversationStore.isConversationLoading ?
            <div style={{marginTop: '30%'}}>
              <CommonLoading text={<>대화 로딩 중입니다.<br/> 잠시만 기다려주세요.</>} />
            </div>
            :
            <React.Fragment>
              {conversationStore.conversation !== '' &&
                  <WrapBox>
                    <CardBoxViewer>
                      <ChipBox>
                        {conversationStore.conversation.topicWordList.map((word, index) => {
                          const color = getColorByWord(word);
                          return (
                              <Chip key={index} label={word} style={{ backgroundColor: color} } />
                          )
                        })}
                      </ChipBox>
                      <BoxTitle>
                        <ListTitle variant="subtitle2">
                          {conversationStore.conversation.firstMessage}
                        </ListTitle>
                        <ListCaption>{getDate(conversationStore.conversation.startDatetime)} / {convertSecondsToTime(conversationStore.conversation.duration)}</ListCaption>
                      </BoxTitle>
                      <ListBox>
                        <ul>
                          {conversationStore.conversation.summaryList.map((summary, index) => (
                              <li key={index}>{summary}</li>
                          ))}
                        </ul>
                      </ListBox>
                    </CardBoxViewer>

                    <ChatBox>
                      {conversationStore.conversation.dialogList.map((dialog, index) => {
                        if (dialog.role === 'user') {
                          return (
                              <QuestionBox key={index}>
                                <span>{dialog.message}</span>
                              </QuestionBox>
                          )
                        } else {
                          return (
                              <AnswerBox key={index}>
                                <span>{dialog.message}</span>
                              </AnswerBox>
                          )
                        }
                      })}
                    </ChatBox>
                  </WrapBox>
              }
            </React.Fragment>
        }
      </React.Fragment>
    </Root>
  );
}

export default inject('conversationStore')(observer(ConversationContentDetailComponent));