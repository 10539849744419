import { Box, Button, Menu, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SelectBox = styled(Box)(() => ({
  textAlign: "left",
}));

export const ButtonStyle = styled(Button)(() => ({
  textAlign: "left",
  padding: 0,
  "& > svg": {
    marginLeft: 8,
  },
}));

export const SubtitleStyle = styled(Typography)(() => ({
  fontSize: "1.25rem",
  fontWeight: 800,
  color: "#333",
  lineHeight: 1.5,
}));
export const BodyStyle = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: 500,
  color: "#909195",
}));

export const InfoText = styled("p")(() => ({
  display: "flex",
  alignItems: "center",
  margin: "8px 0 0 0",
  fontSize: "0.75rem",
  fontWeight: 400,
  color: "#909195",
  "& svg": {
    marginRight: 4,
  },
}));

export const MenuStyle = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    maxHeight: 148,
    padding: 8,
    backgroundColor: "#fff",
    border: "1px solid #F0F1F5",
    borderRadius: 10,
    boxShadow: "none",
    overflowY: "auto",
  },
  "& .MuiList-root": {
    padding: 0,
    margin: 0,
  },
  "& .MuiMenuItem-root": {
    minHeight: "auto",
    padding: 8,
    borderRadius: 4,
    fontSize: "0.75rem",
    // "&:hover, &:focus": {
    //   backgroundColor: "#FDD751",
    // },
  },
}));
