import React from "react";

import {BoxIn, TitleText, ContentText} from "./Styled/LogginInStyled";
import {Loader} from "../../common/Styled/CommonStyled";


export default function LogginIn() {


  return (
      <BoxIn>
        {/*<Loader></Loader>*/}
          <Loader>
          <svg className="spinner" viewBox="0 0 48 48">
              <circle className="path" cx="24" cy="24" r="21.5" fill="none" strokeWidth="5"></circle>
          </svg>
          </Loader>
        <TitleText>포코로 보드</TitleText>
        <ContentText>로그인 중입니다. <br/> 잠시만 기다려주세요</ContentText>
      </BoxIn>
  );
}
