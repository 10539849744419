import { Repository } from './Repository';

export default class AuthRepository extends Repository {
    constructor() {
        super();

        this.authRequestPrefix = '/api/v1/authentications';
    }

    login = (param) => {
        return this.getRequestPromise('post', this.authRequestPrefix + `/signin`, param);
    };

    checkLogin = () => {
        return this.getRequestPromise('get', this.authRequestPrefix + `/signcheck`);
    };

    logout = () => {
        return this.getRequestPromise('post', this.authRequestPrefix + `/signout`);
    };

    agreeTerms = (userId, param) => {
        return this.getRequestPromise('put', this.authRequestPrefix + `/${userId}/terms`, param);
    }

    updateNickname = (userId, param) => {
        return this.getRequestPromise('put', this.authRequestPrefix + `/${userId}/nickname`, param);
    }
}
