import React from "react";
import ReactWordcloud from "react-wordcloud";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

// import words from "./words";
const options = {
  // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
  colors: ['#305580', '#B79470', '#FCB100', '#9277BF', '#5CBA47', '#DE6F9E', '#DE765F', '#90CBFB', '#FEA443'],
  fontFamily: "Roboto",
  fontWeight: "700",
  fontSizes: [5, 60],
  rotations: 3,
  rotationAngles: [0],
};

export default function WordCloudChart(props) {
  const wordLst = [];
  for (const word of props.wordList) {
    wordLst.push({text: word.word, value: word.count});
  }

  return (
      <div style={{ width: "100%", height: "100%" }}>
        <ReactWordcloud words={wordLst} options={options} />
      </div>
  );
}
