import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ButtonStyle } from "../../CommonComponent/Styled/CommonButtonStyled";
export const Root = styled(Box)(() => ({
  padding: "40px 16px",
  backgroundColor: "#F0F1F5",
  borderRadius: "30px 30px 0 0",
}));

export const Contents = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  marginTop: 16,
  "& .MuiTypography-subtitle2": {
    fontWeight: 400,
  },
}));
export const ChipBox = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  marginBottom: 8,
  "& .MuiChip-root": {
    height: 19,
    lineHeight: 1,
    "& .MuiChip-label": {
      padding: "4px 16px",
      fontSize: "0.65rem",
      color: "#fff",
    },
  },
}));

export const BoxTitle = styled(Box)(() => ({
  "& .MuiTypography-root": {
    textAlign: "left",
  },
}));

export const ListBox = styled(Box)(() => ({
  borderTop: "1px solid #F0F1F5",
  marginTop: 16,
  paddingTop: 16,
  "& ul": {
    padding: "0 0 0 24px",
    margin: 0,
  },
  "& li": {
    fontSize: "0.875rem",
    color: "#333",
    marginBottom: 3,
  },
}));

export const ViewerButtonStyle = styled(ButtonStyle)(() => ({
  border: "1px solid #C9C9CA",
  color: "#909195",
  marginTop: 16,
}));
