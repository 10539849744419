import React from "react";
import {
  AllFormControlLabelStyle,
  FormControlLabelBox,
  FormControlLabelBoxIn,
  FormControlLabelStyle,
  IconButtonStyle,
  Root,
} from "./Styled/CheckedComponentStyled";
import { Checkbox, Typography } from "@mui/material";
import { ReactComponent as CheckIcon } from "../../common/Images/CheckIcon.svg";
import { ReactComponent as UnCheckIcon } from "../../common/Images/UnCheckIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../common/Images/ArrowRightIcon.svg";
import {inject, observer} from "mobx-react";
import {TermsOfPrivacyUrl, TermsOfUseUrl} from "../../stores/UserStore";

function CheckedComponent(props) {
  const {authStore} = props;

  const handleChangeTermsOfAll = (event) => {
    authStore.setTermsOfAll(event.target.checked);
  };

  const handleChangeTermsOfAge = (event) => {
      authStore.setTermsOfAge(event.target.checked);
  };

  const handleChangeTermsOfUse = (event) => {
      authStore.setTermsOfUse(event.target.checked);
  };

  const handleChangeTermsOfPrivacy = (event) => {
      authStore.setTermsOfPrivacy(event.target.checked);
  };

  const handleClickTermsOfUse = () => {
      window.open(TermsOfUseUrl);
  }

  const handleClickTermsOfPrivacy = () => {
      window.open(TermsOfPrivacyUrl);
  }


  const children = (
    <FormControlLabelBox>
      <FormControlLabelBoxIn>
        <FormControlLabelStyle
          label={
            <Typography>
              만 14세 이상 <span>(필수)</span>
            </Typography>
          }
          control={
            <Checkbox
              checked={authStore.termsOfAge}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              onChange={handleChangeTermsOfAge}
              disableRipple
            />
          }
        />
      </FormControlLabelBoxIn>

      <FormControlLabelBoxIn>
        <FormControlLabelStyle
          label={
            <Typography>
              서비스 이용약관 동의 <span>(필수)</span>
            </Typography>
          }
          control={
            <Checkbox
                checked={authStore.termsOfUse}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              onChange={handleChangeTermsOfUse}
              disableRipple
            />
          }
        />

        <IconButtonStyle disableRipple onClick={handleClickTermsOfUse}>
          <ArrowRightIcon />
        </IconButtonStyle>
      </FormControlLabelBoxIn>

      <FormControlLabelBoxIn>
        <FormControlLabelStyle
          label={
            <Typography>
              개인정보 수집 및 이용 동의 <span>(필수)</span>
            </Typography>
          }
          control={
            <Checkbox
              checked={authStore.termsOfPrivacy}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              onChange={handleChangeTermsOfPrivacy}
              disableRipple
            />
          }
        />

        <IconButtonStyle disableRipple onClick={handleClickTermsOfPrivacy}>
          <ArrowRightIcon />
        </IconButtonStyle>
      </FormControlLabelBoxIn>
    </FormControlLabelBox>
  );

  return (
    <Root>
      <AllFormControlLabelStyle
        label="약관에 모두 동의"
        control={
          <Checkbox
            checked={authStore.termsOfAge && authStore.termsOfUse && authStore.termsOfPrivacy}
            onChange={handleChangeTermsOfAll}
            indeterminateIcon={<UnCheckIcon />}
            checkedIcon={<CheckIcon />}
            icon={<UnCheckIcon />}
            disableRipple
          />
        }
      />
      {children}
    </Root>
  );
}

export default inject('authStore')(observer(CheckedComponent));
