import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { BoxIn } from "../../../CommonComponent/Styled/CommonEmptyPoKoRoStyled";
import { StyledInput } from "../../../CommonComponent/Styled/CommonInputStyled";

export const Root = styled(Box)(() => ({
  width: "100%",
  padding: "24px 16px 95px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  // gap: 24,
}));

export const TitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "#212121",
    lineHeight: "28px",
    marginBottom: 8,
  },
}));

export const ControlBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const CommonInputStyle = styled(StyledInput)(() => ({
  "& input": {
    height: 64,
    padding: "16px 16px 16px 8px!important",
    fontSize: "0.875rem",
    color: "#C9C9CA",
    boxSizing: "border-box",
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C9C9CA!important",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #C9C9CA!important",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C9C9CA!important",
    },
  },
  "&.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C9C9CA!important",
    },
  },
}));

export const ButtonIconStyle = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
    color: "#909195",
    paddingRight: 8,
  },
}));

export const SelectStyle = styled(Select)(() => ({
  color: "#333",
  fontSize: "0.875rem",
  "& .MuiSelect-select.MuiSelect-standard": {
    paddingRight: "0!important",
  },
  "&:before, &:after": {
    display: "none",
  },
  "& .MuiSelect-standard:focus": {
    backgroundColor: "transparent",
  },
}));

export const MenuItemStyle = styled(MenuItem)(() => ({
  minHeight: "max-content",
  fontSize: "0.75rem",
  padding: "6px 8px",
  margin: "0 8px",
  borderRadius: 4,
  "&:hover, .Mui-focused": {
    backgroundColor: "#FDD751",
  },
  "&.Mui-selected": {
    backgroundColor: "#FDD751",
    "&:hover": {
      backgroundColor: "#FDD751",
    },
  },
}));

export const EmptyTextBox = styled(BoxIn)(() => ({
  textAlign:'center',
  "& .MuiTypography-subtitle1": {
    fontSize: "1rem",
    fontWeight: 700,
  },
}));

export const ErrorBox = styled(BoxIn)(() => ({
  gap:32,
  textAlign:'center',
  "& .MuiTypography-subtitle1": {
    fontSize: "1rem",
    fontWeight: 700,
  },
}));

export const ButtonText = styled(Button)(() => ({
  fontSize:'0.75rem',
  color:'#909195'
}));