import {makeAutoObservable} from "mobx";


const logPrefix  = "[ConversationStore]"
export default class ConversationStore {

    constructor(props) {
        this.conversationRepository = props.conversationRepository;

        this.isConversationListLoading = false;
        this.isConversationLoading = false;

        this.type = 'list';
        this.conversationCount = 0;
        this.conversationList = [];
        this.conversation = '';
        this.tempSearchKeyword = '';
        this.searchKeyword = '';
        this.searchSortDirection = 'descending';
        this.rowsPerPage = 10;
        this.page = 1;
        this.topScrollMessage = '';

        makeAutoObservable(this);
    }

    init = () => {
        this.type = 'list';
        this.conversationCount = 0;
        this.conversationList = [];
        this.tempSearchKeyword = '';
        this.searchKeyword = '';
        this.searchSortDirection = 'descending';
        this.rowsPerPage = 10;
        this.page = 1;
        this.topScrollMessage = '';
    }

    setType = (type) => {
        this.type = type;
    }

    setTempSearchKeyword = (keyword) => {
        this.tempSearchKeyword = keyword;
    }

    setSearchKeyword = (keyword) => {
        this.searchKeyword = keyword;
    }

    setSearchSortDirection = (direction) => {
        this.page = 1;
        this.searchSortDirection = direction;
    }

    setPage = (page) => {
        this.page = page;
    }

    setTopScrollMessage = (message) => {
        this.topScrollMessage = message;
    }

    * getConversationList(userId, deviceId, callback) {
        try {
            console.log(logPrefix, 'Start getConversationList.');
            this.isConversationListLoading = true;

            const response = yield this.conversationRepository.getConversationList(userId, deviceId, this.searchKeyword, this.rowsPerPage, this.page, this.searchSortDirection);

            this.conversationCount = response.data.totalCount;
            this.conversationList = response.data.dialogList;
            
            this.isConversationListLoading = false;
            console.log(logPrefix, 'Finished getConversationList.');

            callback && callback();
        } catch(e) {
            this.conversationList = '';
            console.log(logPrefix, 'Failed getConversationList. error: ' + e);
        } finally {
            this.isConversationListLoading = false;
        }
    }

    * getConversation(dialogId, callback) {
        try {
            console.log(logPrefix, 'Start getConversation.');
            this.isConversationLoading = true;

            const response = yield this.conversationRepository.getConversation(dialogId);

            this.conversation = response.data;

            this.isConversationLoading = false;
            console.log(logPrefix, 'Finished getConversation.');

            callback && callback();
        } catch(e) {
            this.conversation = '';
            console.log(logPrefix, 'Failed getConversation. error: ' + e);
        } finally {
            this.isConversationLoading = false;
        }
    }
}
