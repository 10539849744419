import React, {useEffect} from "react";

import {List, ListItem, ListItemText, Typography} from "@mui/material";

import EmptyImage from "../../../common/Icons/EmptyImage.svg";
import { ReactComponent as MoreIcon } from "../../../common/Images/MoreIcon.svg";
import { ReactComponent as FilterIcon } from "../../../common/Icons/FilterIcon.svg";
import CommonDialog from "../../CommonComponent/CommonDialog";
import CommonEmptyPoKoRo from "../../CommonComponent/CommonEmptyPoKoRo";
import FilterDrawer from "./FilterDrawer";
import ReportDetail from "./ReportDetail";
import {
  DateTextStyle,
  EmptyBox,
  IconButtonStyle,
  ListBox,
  ListBoxIn,
  PopoverBox,
  ReportBoxStyle,
  Root,
  SnackbarBox,
  TitleTextStyle,
  SubTitleTextStyle,
  ControlBox,
  ButtonText,
  StateBox,
} from "./Styled/ReportStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";


function Report(props) {
  const {authStore, userStore, loadingStore, reportStore} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    loadingStore.setIsLoading(reportStore.getIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportStore.getIsLoading]);

  useEffect(() => {
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReportList = () => {
    reportStore.getReportList(authStore.loginUser.id, userStore.selectedDevice.deviceId);
  }

  const handleClickMoreOpen = (event, report) => {
    reportStore.setDeleteReport(report);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    reportStore.setDeleteReport('');
    setDeleteDialog(false);
  };

  const handleClickSnackbarMessage = () => {
    setDeleteDialog(false);

    reportStore.updateReportDisplay(authStore.loginUser.id, userStore.selectedDevice.deviceId, () => setSnackbarMessage(true));
  };

  const handleCloseSnackbarMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage(false);
  };

  const handleClickDetailOpen = () => {
    reportStore.setIsDetailOpen(true);
  };

  const handleDetailClose = () => {
    reportStore.setIsDetailOpen(false);
  };

  const handleClickReport = (report) => {
    reportStore.getReport(report, () => handleClickDetailOpen());
  }

  const getDate = (reportCreatedDatetime) => {
    if (reportCreatedDatetime !== null) {
      return dayjs(reportCreatedDatetime).format('YYYY.MM.DD');
    } else {
      return '';
    }
  }

  //checkPb 조건변경

  const [filterOpen, setFilterOpen] = React.useState(false);

  const toggleDrawerFilter = (newOpen) => () => {
    setFilterOpen(newOpen);
  };

  const closeFilter = () => {
    reportStore.setTempSearchSection(reportStore.searchSection);
    reportStore.setTempSearchSortDirection(reportStore.searchSortDirection);

    if (reportStore.searchSection !== 'direct') {
      reportStore.setDirectSearchStartDate({year: 0, month: 0, week: 0});
      reportStore.setDirectSearchEndDate({year: 0, month: 0, week: 0});
      reportStore.setStartPickerDate({year: 0, month: 0, week: 0});
      reportStore.setEndPickerDate({year: 0, month: 0, week: 0});
    }

    setFilterOpen(false);
  }

  const handleClickAll = () => {
    reportStore.setSearchSection('all');
    getReportList();
  }

  const handleClickChangeSortDirection = () => {
    if (reportStore.searchSortDirection === 'descending') {
      reportStore.setSearchSortDirection('ascending');
      reportStore.setTempSearchSortDirection('ascending');
    } else {
      reportStore.setSearchSortDirection('descending');
      reportStore.setTempSearchSortDirection('descending');
    }
    getReportList();
  }

  return (
    <Root>
      <TitleTextStyle>리포트</TitleTextStyle>
      <SubTitleTextStyle>지난 리포트를 확인할 수 있어요.</SubTitleTextStyle>

      <ControlBox>
        <ButtonText onClick={toggleDrawerFilter(true)}><FilterIcon/> 조건 변경</ButtonText>
        <StateBox>
          <div onClick={handleClickAll}>전체</div> <span>|</span> <div onClick={handleClickChangeSortDirection}>{reportStore.searchSortDirection === 'descending' ? '최신순' : '과거순'}</div>
        </StateBox>
      </ControlBox>

      {userStore.selectedDevice === '' ?
          // <CardBox>
            <CommonEmptyPoKoRo
                image={EmptyImage}
                text={
                  <Typography>
                    연결된 포코로가 없어
                    <br />
                    대화목록을 불러올 수가 없습니다.
                  </Typography>
                }
            />
          // </CardBox>
          :
          <React.Fragment>
              {reportStore.reportList.filter(r => r.display === true).length > 0 ? (
                  reportStore.reportList.map((report, i) => {
                    if (report.display === true) {
                      return (
                          <ReportBoxStyle key={i}>
                          <ListBox key={i}>
                            <ListBoxIn>
                              <Typography style={{cursor: 'pointer'}} onClick={() => handleClickReport(report)}>{report.title}</Typography>
                              <IconButtonStyle onClick={(event) => handleClickMoreOpen(event, report)} disableRipple>
                                <MoreIcon />
                              </IconButtonStyle>
                            </ListBoxIn>
                            <DateTextStyle>생성일 : {getDate(report.reportCreatedDatetime)}</DateTextStyle>
                          </ListBox>
                          </ReportBoxStyle>
                      )
                    } else {
                      return null;
                    }
                  })
              ) : (
                  <EmptyBox>
                    <Typography>조회된 리포트가 없습니다.</Typography>
                  </EmptyBox>
              )}

              <PopoverBox
                  id="simple-popper"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseMoreMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
              >
                <List>
                  <ListItem onClick={handleClickDelete}>
                    <ListItemText primary={"삭제"} />
                  </ListItem>
                </List>
              </PopoverBox>


            <SnackbarBox
                open={snackbarMessage}
                autoHideDuration={2000}
                message="리포트가 삭제되었습니다."
                onClose={handleCloseSnackbarMessage}
            />

            <CommonDialog
                open={deleteDialog}
                title={"리포트를 삭제하시겠습니까?"}
                subText={
                  <span>
            삭제된 리포트는 다시 볼 수 없습니다.
            <br />
            그래도 삭제하시겠습니까?
          </span>
                }
                LeftButtonText={"아니오"}
                RightButtonText={"예"}
                onClick1={handleCloseDelete}
                onClick2={handleClickSnackbarMessage}
            />

            <ReportDetail open={reportStore.isDetailOpen} onClose={handleDetailClose} />


            <FilterDrawer open={filterOpen} onClose={closeFilter}/>

          </React.Fragment>
      }
    </Root>
  );
}

export default inject('authStore', 'userStore', 'loadingStore', 'reportStore')(observer(Report));
