import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import SignInBackgroundImage from "../../../common/Images/SignInBackgroundImage.png";
import BgCommentTiny from "../../../common/Images/BgCommentTiny.svg";

export const Root = styled(Box)(() => ({
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${SignInBackgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0 16px",
  boxSizing: "border-box",
}));

export const ContentsBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40% 0 40px",
}));

export const ButtonBox = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  marginBottom: "40%",
}));

export const ButtonStyle = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    position: "relative",
    width: "100%",
    height: 48,
    padding: "0px 16px",
    boxSizing: "border-box",
    borderRadius: 8,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#fff",
      textTransform: "none",
    },
    "&:after": {
      content: "''",
    },
  },
}));

export const NoeButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #FF993C",
    background: "#FF993C",
    "&:hover": {
      background: "#FF993C",
    },
    "&:before": {
      width: 15,
      height: 18,
    },
  },
}));

export const GoogleButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #c9c9ca",
    background: "#fff",
    "&:hover": {
      background: "#fff",
    },
    "& p": {
      color: "#909195",
    },
    "&:after": {
      width: 20,
      height: 20,
    },
  },
}));

export const AppleButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #212121",
    background: "#212121",
    marginBottom: 0,
    "&:hover": {
      background: "#212121",
    },
    "&:after": {
      width: 14,
      height: 18,
    },
  },
}));
export const RecentlyBox = styled(Box)(() => ({
  position:'absolute',
  right:0,
  top:-5,
  fontSize:'0.75rem',
  color:'#333',
  padding:'4px 12px',
  backgroundImage: `url(${BgCommentTiny})`,
  backgroundSize: "container",
  backgroundRepeat: "no-repeat",
}));

export const TitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.375rem",
    fontWeight: 700,
    color: "#212121",
    marginTop: 30,
  },
}));

export const TextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#212121",
    lineHeight: "19.6px",
    marginTop: 5,
    textAlign: "center",
  },
}));

// 로그인 상세
export const SignInRoot = styled(Box)(() => ({
  width: "100%",
  height: "100vh",
}));

// 로그인 상세 정보
export const SignInContentsBox = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 46px)",
  padding: "16px 16px 40px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const InfoBox = styled(Box)(() => ({
  width: "100%",
  padding: "0 8px 16px",
  boxSizing: "border-box",
}));

export const BoxMarginTop = styled(Box)(() => ({
  marginTop: 8,
}));

export const TextBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const SignInTitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    fontWeight: 700,
    color: "#212121",
    marginBottom: 8,
  },
}));

export const SignInTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#333",
    lineHeight: "19.6px",
    textAlign: "left",
  },
}));

export const InfoText = styled("ul")(() => ({
  margin: "10px 0 0",
  paddingLeft: 15,
  "& li": {
    padding: 0,
    fontSize: "0.75rem",
    color: "#909195",
    fontWeight: 400,
    lineHeight: "16.8px",
    textAlign: "left",
    "& span": {},
  },
}));
