import React, {useEffect} from "react";

import { Typography } from "@mui/material";

import { ReactComponent as AppleLogo } from "../../common/Images/AppleLogo.svg";
import { ReactComponent as GoogleLogo } from "../../common/Images/GoogleLogo.svg";
import { ReactComponent as NoeLABLogo } from "../../common/Images/NoeLABLogo.svg";
import { ReactComponent as PoKoRoIcon } from "../../common/Images/PoKoRoIcon.svg";

import {
  AppleButtonStyle,
  ButtonBox,
  ContentsBox,
  GoogleButtonStyle,
  NoeButtonStyle,
  Root,
  TextStyle,
  TitleTextStyle,
    RecentlyBox,
} from "./Styled/SignInStyled";
import {inject, observer} from "mobx-react";
import qs from "query-string";
import {useHistory} from "react-router-dom";

import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import {LocalStorageLoginType, State} from "../../stores/AuthStore";
import LogginIn from "./LogginIn";

function SignIn(props) {
    const {authStore} = props;
    const history = useHistory();


    //TODO url 수정
    const type = 'live';
    let authServerUrl = 'https://ndp-dev.onthe.live:7443';
    if (type === 'live') {
        authServerUrl = 'https://auth.neolab.net';
    }

    let clientId = '';

    let redirectUri = 'https://pokoro-dev.onthe.live/loginCheck.html';
    if (type === 'live') {
        redirectUri = 'https://board.pokoro.ai/loginCheck.html';
    } else if (type === 'local') {
        redirectUri = 'http://localhost:3000/loginCheck.html';
    }

    useEffect(() => {
        if (authStore.loginCode === 'fail') {
            authStore.setLoginCode('');
        } else if (authStore.loginCode !== '') {
            authStore.doLogin(history);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.loginCode])

    const login = async () => {
        return new Promise((resolve, reject)=> {
            const authUrl = `${authServerUrl}/oauth/v2/authorize?client_id=${clientId}&response_type=code&scope=openid&redirect_uri=${redirectUri}`;
            const popup = window.open(authUrl, "_blank", "width = 574, height = 890, top = 0, left = 0,scrollbars=no, resizable=no, toolbars=no, menubar=no, popup=yes, location = no, noopener=0");
            if(popup === null){
                resolve('nullPopup');
            }

            function receiveMessage(event) {
                if (event.origin !== window.location.origin) {
                    return;
                }

                if (event.data.constructor !== String || event.data.indexOf("login/") === -1) {
                    return;
                }

                popup.close();

                const search = event.data.substr(6);
                let { code } = qs.parse(search);
                code = (String(code)).replace(/[^0-9a-zA-Z]/g,"");
                if (code !== "null") {
                    code = code.substring(0,8);
                    try {
                        if (code) {
                            setTimeout(()=>{
                                authStore.setLoginCode(code);
                            }, 100)
                            resolve(true);
                        }
                    } catch(e) {
                        resolve(e);
                    }
                } else {
                    try {
                        if (code) {
                            setTimeout(()=>{
                                authStore.setLoginCode('fail');
                            }, 100)
                            resolve(false);
                        }
                    } catch(e) {
                        resolve(e);
                    }
                }
            }
            window.addEventListener("message", receiveMessage);

            const interval = setInterval(()=>{
                if (popup !== null && popup.closed) {
                    clearInterval(interval);
                    window.removeEventListener("message", receiveMessage);
                    if(authStore.loginCode === ''){
                        resolve(false);
                    }
                }
            }, 500);
        })
    }

    const handleClickLogin = async (loginType) => {
        if (loginType === 'NeoLab') {
            authStore.setLoginType('NeoLab');
            clientId = 'ioted_android_neolab';
        } else if (loginType === 'Google') {
            authStore.setLoginType('Google');
            clientId = 'ioted_android_google';
        } else if (loginType === 'Apple') {
            authStore.setLoginType('Apple');
            clientId = 'ioted_ios_apple';
        }
        const isLogin = await login();

        if (isLogin !== true) {
            console.log("====Login fail");
            handleClickOpen();
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {authStore.loginState === State.Pending ?
                <LogginIn />
                :
                <Root>
                    <ContentsBox>
                        <PoKoRoIcon />
                        <TitleTextStyle>포코로 보드</TitleTextStyle>
                        <TextStyle>
                            포코로와 내 아이가 무슨 대화를 했는지
                            <br />
                            얼마나 사용했는지 확인할 수 있어요.
                        </TextStyle>
                    </ContentsBox>
                    <ButtonBox>
                        <NoeButtonStyle disableRipple onClick={() => handleClickLogin('NeoLab')}>
                            {(localStorage.getItem(LocalStorageLoginType) && localStorage.getItem(LocalStorageLoginType) === 'NeoLab') &&
                                <RecentlyBox>최근 사용</RecentlyBox>
                            }
                            <NoeLABLogo />
                            <Typography>NeoLAB 로그인</Typography>
                        </NoeButtonStyle>
                        <GoogleButtonStyle disableRipple onClick={() => handleClickLogin('Google')}>
                            {(localStorage.getItem(LocalStorageLoginType) && localStorage.getItem(LocalStorageLoginType) === 'Google') &&
                                <RecentlyBox>최근 사용</RecentlyBox>
                            }
                            <GoogleLogo />
                            <Typography>Google 로그인</Typography>
                        </GoogleButtonStyle>
                        <AppleButtonStyle disableRipple onClick={() => handleClickLogin('Apple')}>
                            {(localStorage.getItem(LocalStorageLoginType) && localStorage.getItem(LocalStorageLoginType) === 'Apple') &&
                                <RecentlyBox>최근 사용</RecentlyBox>
                            }
                            <AppleLogo />
                            <Typography>Apple 로그인</Typography>
                        </AppleButtonStyle>
                    </ButtonBox>
                </Root>
            }

            <CommonComfirmDialog
                open={open}
                title = {'로그인 실패'}
                contents={<React.Fragment>알수없는 오류로 인해 로그인에 실패했습니다. <br/>다시 로그인 해주세요.</React.Fragment>}
                buttonText={'확인'}
                onClick={handleClickClose}
            />

            <CommonComfirmDialog
                open={authStore.loginState === State.Failed}
                title = {'로그인 실패'}
                contents={<React.Fragment>알수없는 오류로 인해 로그인에 실패했습니다. <br/>다시 로그인 해주세요.</React.Fragment>}
                buttonText={'확인'}
                onClick={() => authStore.setLoginState(State.NotAuthenticated)}
            />
        </React.Fragment>
  );
}

export default inject('authStore')(observer(SignIn));
