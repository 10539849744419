import { default as AuthStore } from "./AuthStore";
import UserStore from "./UserStore";
import LoadingStore from "./LoadingStore";
import ModalStore from "./ModalStore";
import ConversationStore from "./ConversationStore";
import ReportStore from "./ReportStore";

import AuthRepository from "../repositories/AuthRepository";
import UserRepository from "../repositories/UserRepository";
import ConversationRepository from "../repositories/ConversationRepository";
import ReportRepository from "../repositories/ReportRepository";

const authRepository = new AuthRepository();
const userRepository = new UserRepository();
const conversationRepository = new ConversationRepository();
const reportRepository = new ReportRepository();

export const stores = {
    authStore:  new AuthStore({authRepository}),
    userStore: new UserStore({userRepository}),
    conversationStore: new ConversationStore({conversationRepository}),
    reportStore: new ReportStore({reportRepository}),
    loadingStore: new LoadingStore(),
    modalStore: new ModalStore(),
};