import React from "react";

import { Box } from "@mui/material";

import Chart from "react-apexcharts";
import {styled} from "@mui/material/styles";


const Root = styled(Box)(() => ({
    '& .apexcharts-toolbar':{
        display:'none',
    },
}));

export default function BarChartComponent(props) {
    const data = props.data;

    const options = {
        xaxis: {
            categories: [data.lastWeekTitle, data.thisWeekTitle],
            colors: ["#C9C9CA", "#FDD751"],
            labels: {
                style: {
                    colors: "#909195",
                    fontSize:"0.625rem",
                }
            }
        },
        yaxis: {
            tickAmount: 3,
            min: 0,
            labels: {
                style: {
                  colors:"#909195",
                  fontSize:"0.625rem",
                },
                formatter: function (val) {
                    return val.toFixed(0) + " 분";
                },
            },
            axisBorder: {
                show: true,
            },
        },
        chart: {
            type: 'bar',
        },
        plotOptions: {
            bar: {
                borderRadius:4,
                columnWidth: '40px',
                barHeight: '100%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                    position: 'top'
                },
            }
        },
        colors: ["#C9C9CA", "#FDD751"],
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + " 분";
            },
            offsetY: -35,
            style: {
                fontSize: '12px',
                fontWeight:400,
                colors: ["#909195", "#333"]
            }
        },
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        grid: {
            row: {
                colors: ['#fff', 'transparent'],
                opacity: 0.5,
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        },
        tooltip: {
            enabled: false
        },
        legend: {
            show: false,
        }
    };

    const getOptions = () => {
        if (data.lastWeek === 0 && data.thisWeek === 0) {
            options.yaxis.max = 60;
        }
        return options;
    }
    const series = [
        // {data: [data.lastWeek, data.thisWeek]}
        {data: [Math.round(data.lastWeek / 60), Math.round(data.thisWeek / 60)]}
    ];
    return (
        <Root>
            <Chart options={getOptions()}
                   series={series}
                   type="bar"
                   height={300}/>
        </Root>
    );
}

