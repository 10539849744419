import { Box, Button, Dialog, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    background: "rgba(0, 0, 0, 0.8)",
  },
  "& .MuiPaper-root": {
    minWidth: 328,
    background: "#fff",
    padding: "32px 24px",
    boxSizing: "border-box",
    borderRadius: 20,
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    color: "#333",
    fontWeight: 700,
    lineHeight: "25.2px",
    textAlign: "center",
  },
}));

export const TextStyle = styled(Typography)(({ theme, color }) => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    color: color ? color : "#333",
    fontWeight: 400,
    lineHeight: "19.6px",
    textAlign: "center",
    marginTop: 10,
  },
}));

export const ActionBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 25,
}));

export const ButtonStyle = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    minWidth: 136,
    padding: 16,
    borderRadius: 8,
    boxSizing: "border-box",
    "& p": {
      fontSize: "0.875rem",
      color: "#333",
      lineHeight: "19.6px",
      fontWeight: 400,
    },
  },
}));

export const LeftButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    background: "#FDD751",
    marginRight: 8,
    "&:hover": {
      background: "#FDD751",
    },
  },
}));

export const RightButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    background: "#fff",
    outline: "1px solid #C9C9CA",
    "&:hover": {
      background: "#fff",
    },
  },
}));
