import {
  Box,
  Button,
  IconButton,
  Popover,
  Snackbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../../common/Styled/CommonStyled";

export const Root = styled(Box)(() => ({
  width: "100%",
  padding: "24px 16px 95px",
  boxSizing: "border-box",
}));

export const TitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "#212121",
    lineHeight: "28px",
    marginBottom: 4,
  },
}));
export const SubTitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#333",
    lineHeight: "19.6px",
  },
}));

export const BoxStyle = styled(Box)(() => ({
  width: "100%",
  borderRadius: 24,
  padding: 24,
  background: "#FDD751",
  boxSizing: "border-box",
}));

export const ReportBoxStyle = styled(CardBox)(() => ({
  borderRadius:16,
  padding: "16px",
  boxShadow:'none',
  boxSizing: "border-box",
}));

export const BoxTitleStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    fontWeight: 700,
    color: "#333",
    lineHeight: "25.2px",
  },
}));

export const BoxTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#333",
    lineHeight: "19.6px",
    marginBottom: 16,
  },
}));

export const BoxImageStyle = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  margin: "16px 0",
  "& img": {
    width: "100%",
  },
}));

export const ListBox = styled(Box)(() => ({
  width: "100%",
  boxSizing: "border-box",
}));

export const ListBoxIn = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& p": {
    width: "calc(100% - 25px)",
    fontSize: "0.875rem",
    color: "#333",
    fontWeight: 400,
    lineHeight: "19.6px",
    letterSpacing: "0.25px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  "&.MuiButtonBase-root": {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
}));

export const DateTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.625rem",
    color: "#909195",
    lineHeight: "14px",
    fontWeight: 400,
  },
}));

export const MoreButton = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    width: "100%",
    minHeight: 10,
    fontSize: "0.75rem",
    color: "#909195",
    lineHeight: "16.8px",
    fontWeight: 400,
    padding: "8px 0",
    marginTop: 16,
    boxSizing: "border-box",
    "&:hover": {
      background: "transparent",
    },
  },
}));

export const EmptyBox = styled(Box)(() => ({
  width: "100%",
  padding: "32px 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& p": {
    fontSize: "0.875rem",
    color: "#909195",
    lineHeight: "19.6px",
    fontWeight: 400,
  },
}));

export const PopoverBox = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 79,
    boxShadow: "2px 2px 2px 0px rgba(0, 0, 0, 0.15)",
    background: "#fff",
    border: "1px solid #F0F1F5",
    borderRadius: 10,
    padding: '8px 0',
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      "& li": {
        padding: 8,
        borderRadius: 0,
        boxSizing: "border-box",
        cursor: "pointer",
        "&:hover": {
          background: "#FDD751",
        },
        "& .MuiListItemText-root": {
          textAlign: "center",
          margin: 0,
          "& span": {
            fontSize: "0.75rem",
            color: "#333",
            fontWeight: 400,
            lineHeight: "16.8px",
          },
        },
      },
    },
  },
}));

export const SnackbarBox = styled(Snackbar)(() => ({
  "&.MuiSnackbar-root": {
    bottom: 91,
    left: 25,
    right: 25,
  },
  "& .MuiPaper-root": {
    width: "100%",
    padding: "8px 8px",
    boxSizing: "border-box",
    background: "#212121",
    borderRadius: 8,
    boxShadow: "none",
    justifyContent: "center",
    "& .MuiSnackbarContent-message": {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "19.6px",
      letterSpacing: "0.25px",
      color: "#fff",
    },
  },
}));

export const ControlBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop:24,
  marginBottom:8,
}));


export const ButtonText = styled(Button)(() => ({
  fontSize:'0.875rem',
  fontWeight:400,
  color:'#333',
  padding:0,
  '& svg':{
    marginRight:4,
  },
}));

export const StateBox = styled(Box)(() => ({
  display:'flex',
  alignItems:'center',
  fontSize:'0.75rem',
  color:'#909195',
  lineHeight: '16.8px',
  '& > span':{
    fontSize:'0.625rem',
    margin:'-2px 4px 0 ',
  },
}));


