import React from "react";

import {inject, observer} from "mobx-react";
import ConversationList from "./ConversationList";
import ConversationContentDetailComponent from "./ConversationContentDetailComponent";


function Conversation(props) {
  const {conversationStore} = props;

  return (
      <React.Fragment>
        {conversationStore.type === 'list' ?
            <ConversationList />
            :
            <ConversationContentDetailComponent />
        }
      </React.Fragment>
  );
}

export default inject('conversationStore')(observer(Conversation));